import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CREATE_TYPE,
  DELETE_TYPE,
  GET_TYPE,
  GET_TYPES,
} from "./types";
import TypeService from "../../services/transaction_type.service";
import axios from "axios";

export const getTypesThunk = createAsyncThunk(
  GET_TYPES,
  async (payload, { rejectWithValue }) => {
    try {
      const res = await TypeService.getTypes();
      return res.data.data;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const createTypesThunk = createAsyncThunk(
  CREATE_TYPE,
  async (payload: { name: string }, { rejectWithValue }) => {
    try {
      await TypeService.createType(payload.name);
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);
  
export const deleteTypesThunk = createAsyncThunk(
  DELETE_TYPE,
  async (payload: { id: string }, { rejectWithValue }) => {
    try {
      await TypeService.deleteType(payload.id);
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const getTypeThunk = createAsyncThunk(
  GET_TYPE,
  async (payload: { id: string }, { rejectWithValue }) => {
    try {
      const res = await TypeService.getType(payload.id);
      return res.data.data;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const updateTypeThunk = createAsyncThunk(
  GET_TYPE,
  async (payload: { id: string, name: string }, { rejectWithValue }) => {
    try {
      const res = await TypeService.updateType(payload.id, payload.name);
      return res.data.data;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);