import styled from "styled-components";
import robotIcon from "../../assets/robotIcon.png";
import arrowRight from "../../assets/arrow_right.svg";
import {
  Alert,
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Snackbar,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import CardMerchant from "../../components/MerchantCard";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  AuthStepContext,
  AuthStepContextType,
  AuthStepEnum,
} from "../../context/authStep";
import MerchantDetailComponent from "../../components/MerchantDetail";
import SearchInput from "../../components/SearchInput";
import {
  getFavoriteMerchantsThunk,
  getMerchantDetailThunk,
  getMerchantsThunk,
} from "../../store/actions/merchant.action";
import {
  MerchantDetailResponseDto,
  MerchantDto,
} from "../../models/merchant.model";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { getUserInfoThunk } from "../../store/actions/auth.action";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return <div>{value === index && children}</div>;
}

const AppsComponent = () => {
  const [cateogryMerchant, setCategoryMerchant] = useState("");
  const [alphabet, setAlphabet] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const navigate = useNavigate();
  const authState = useAppSelector((state) => state.auth);
  const { handleSetStep } = useContext(AuthStepContext) as AuthStepContextType;
  const [weeklyMerchants, setWeeklyMerchants] = useState<MerchantDto[]>([]);
  const [merchants, setMerchants] = useState<MerchantDto[]>([]);
  const [favoriteMerchants, setFavoriteMerchants] = useState<MerchantDto[]>([]);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [merchantDetail, setMerchantDetail] =
    useState<MerchantDetailResponseDto>({});
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(0);

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const handleChangeTab = async (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    if (newValue === 2 && !authState.me) {
      handleSetStep(AuthStepEnum.LOGIN);
      return;
    }
    if (newValue === 2) {
      try {
        const res = await dispatch(getFavoriteMerchantsThunk()).unwrap();
        setFavoriteMerchants(res);
      } catch (e) {
        console.log(e);
      }
    }
    if (newValue === 1) {
      try {
        const res = await dispatch(getMerchantsThunk({ weekly: 0 })).unwrap();
        setMerchants(res);
      } catch (e) {
        console.log(e);
      }
    }
    if (newValue === 0) {
      try {
        const res = await dispatch(getMerchantsThunk({ weekly: 1 })).unwrap();
        setMerchants(res);
      } catch (e) {
        console.log(e);
      }
    }
    setValue(newValue);
  };

  const handleChange = () => {};

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenToast = () => {
    setOpenToast(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const fetchListWeeklyMerchants = async () => {
    try {
      const res = await dispatch(getMerchantsThunk({ weekly: 1 })).unwrap();
      setWeeklyMerchants(res);
    } catch (e) {
      console.log(e);
    }
  };

  const handleClickMerchant = async (merchantId: string) => {
    if (authState.me) {
      const res = await dispatch(
        getMerchantDetailThunk({ id: merchantId })
      ).unwrap();
      setMerchantDetail(res);
      setOpen(true);
    } else {
      handleSetStep(AuthStepEnum.GET_STARTED);
    }
  };

  useEffect(() => {
    fetchListWeeklyMerchants();
  }, []);

  return (
    <Wrapper>
      <WrapperMainContent>
        <Welcome>
          {authState.me
            ? `Welcome, ${authState.me.firstName}!`
            : "Welcome to Kardo.AI!"}
        </Welcome>
        <WrapperChat
          sx={{
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: "4px", sm: "32px" },
          }}
        >
          <Box
            sx={{
              padding: { xs: "0", sm: "0 0 24px" },
            }}
          >
            <ChatTitle>Want to turn every purchase into savings?</ChatTitle>
            <ChatSubTitle>
              Connect with Miles Mate and unlock exclusive savings!
            </ChatSubTitle>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              gap: "24px",
            }}
          >
            <RobotIcon src={robotIcon} />
            <WrapperStartChat>
              <StartChat
                onClick={() => {
                  authState.me
                    ? navigate("/chat-bot")
                    : handleSetStep(AuthStepEnum.LOGIN);
                }}
              >
                Start a chat
                <ArrowRightIcon src={arrowRight} />
              </StartChat>
            </WrapperStartChat>
          </Box>
        </WrapperChat>
        <Box>
          <Tabs
            value={value}
            onChange={handleChangeTab}
            sx={{
              "& .MuiTabs-flexContainer": {
                background: "#fff",
                width: "fit-content",
                borderRadius: "12px 12px 0 0",
                overflow: "hidden",

                "& .MuiTab-root": {
                  color: "#222",
                  fontFamily: "Outfit",
                  fontSize: "18px",
                  fontWeight: "300",
                  padding: "20px 32px",
                },

                "& .Mui-selected": {
                  background: "#D0D7E8",
                  color: "#6D7D93",
                  fontWeight: "700",
                },
              },
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}
          >
            <Tab label="This Week Deals" />
            <Tab label="All Deals" />
            <Tab label="Favorites" />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <WrapperCategoryContent>
            <WrapperFilter
              sx={{
                alignItems: { xs: "baseline", md: "center" },
                flexDirection: { xs: "column", md: "row" },
                gap: { xs: "10px", md: "0" },
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: "45%" },
                }}
              >
                <SearchInput placeholder="Search for merchants" />
              </Box>
              <Box
                sx={{
                  gap: "20px",
                  display: "flex",
                }}
              >
                <SFormControlLabel
                  control={<SCheckbox defaultChecked />}
                  label="Miles"
                />
                <SFormControlLabel
                  control={<SCheckbox color="success" />}
                  label="Cashback"
                />
              </Box>
              <Box
                sx={{
                  gap: "20px",
                  display: "flex",
                }}
              >
                <WrapperSelect>
                  <Select
                    value={cateogryMerchant}
                    fullWidth
                    onChange={handleChange}
                    displayEmpty
                    size="small"
                    sx={{
                      borderRadius: "10px",
                      "& fieldset": {
                        border: "1px solid rgba(109, 125, 147, 0.15)",
                      },
                    }}
                    renderValue={
                      cateogryMerchant !== ""
                        ? () => <Placeholder>Top Offers</Placeholder>
                        : () => <Placeholder>Top Offers</Placeholder>
                    }
                  >
                    <MenuItem value={"top-offers"}>Top Offers</MenuItem>
                  </Select>
                </WrapperSelect>
                <WrapperSelect>
                  <Select
                    value={alphabet}
                    fullWidth
                    onChange={handleChange}
                    displayEmpty
                    size="small"
                    sx={{
                      borderRadius: "10px",
                      "& fieldset": {
                        border: "1px solid rgba(109, 125, 147, 0.15)",
                      },
                    }}
                    renderValue={
                      alphabet !== ""
                        ? undefined
                        : () => <Placeholder>A-Z</Placeholder>
                    }
                  >
                    <MenuItem value={"a-z"}>A-Z</MenuItem>
                  </Select>
                </WrapperSelect>
              </Box>
            </WrapperFilter>
            <WrapperCashMile>{/* <SwitchButton /> */}</WrapperCashMile>
            <WrapperGrid>
              <Grid container spacing={4}>
                {Array.isArray(merchants) &&
                  weeklyMerchants.map((merchant, index) => (
                    <Grid item xs={12} md={6} sm={12} lg={4} xl={4} key={index}>
                      <div onClick={() => handleClickMerchant(merchant._id)}>
                        <CardMerchant
                          logo={merchant.logoUrl}
                          name={merchant.name}
                          kpoint={merchant.discount}
                          cashback={merchant.cashback}
                        />
                      </div>
                    </Grid>
                  ))}
              </Grid>
            </WrapperGrid>
          </WrapperCategoryContent>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <WrapperCategoryContent>
            <WrapperFilter
              sx={{
                alignItems: { xs: "baseline", md: "center" },
                flexDirection: { xs: "column", md: "row" },
                gap: { xs: "10px", md: "0" },
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: "45%" },
                }}
              >
                <SearchInput placeholder="Search for merchants" />
              </Box>
              <Box
                sx={{
                  gap: "20px",
                  display: "flex",
                }}
              >
                <SFormControlLabel
                  control={<SCheckbox defaultChecked />}
                  label="Miles"
                />
                <SFormControlLabel
                  control={<SCheckbox color="success" />}
                  label="Cashback"
                />
              </Box>
              <Box
                sx={{
                  gap: "20px",
                  display: "flex",
                }}
              >
                <WrapperSelect>
                  <Select
                    value={cateogryMerchant}
                    fullWidth
                    onChange={handleChange}
                    displayEmpty
                    size="small"
                    sx={{
                      borderRadius: "10px",
                      "& fieldset": {
                        border: "1px solid rgba(109, 125, 147, 0.15)",
                      },
                    }}
                    renderValue={
                      cateogryMerchant !== ""
                        ? () => <Placeholder>Top Offers</Placeholder>
                        : () => <Placeholder>Top Offers</Placeholder>
                    }
                  >
                    <MenuItem value={"top-offers"}>Top Offers</MenuItem>
                  </Select>
                </WrapperSelect>
                <WrapperSelect>
                  <Select
                    value={alphabet}
                    fullWidth
                    onChange={handleChange}
                    displayEmpty
                    size="small"
                    sx={{
                      borderRadius: "10px",
                      "& fieldset": {
                        border: "1px solid rgba(109, 125, 147, 0.15)",
                      },
                    }}
                    renderValue={
                      alphabet !== ""
                        ? undefined
                        : () => <Placeholder>A-Z</Placeholder>
                    }
                  >
                    <MenuItem value={"a-z"}>A-Z</MenuItem>
                  </Select>
                </WrapperSelect>
              </Box>
            </WrapperFilter>
            <WrapperCashMile>{/* <SwitchButton /> */}</WrapperCashMile>
            <WrapperGrid>
              <Grid container spacing={4}>
                {Array.isArray(merchants) &&
                  merchants.map((merchant, index) => (
                    <Grid item xs={12} md={6} sm={12} lg={4} xl={4} key={index}>
                      <div onClick={() => handleClickMerchant(merchant._id)}>
                        <CardMerchant
                          logo={merchant.logoUrl}
                          name={merchant.name}
                          kpoint={merchant.discount}
                          cashback={merchant.cashback}
                        />
                      </div>
                    </Grid>
                  ))}
              </Grid>
            </WrapperGrid>
          </WrapperCategoryContent>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <WrapperCategoryContent>
            <WrapperFilter
              sx={{
                alignItems: { xs: "baseline", md: "center" },
                flexDirection: { xs: "column", md: "row" },
                gap: { xs: "10px", md: "0" },
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: "45%" },
                }}
              >
                <SearchInput placeholder="Search for merchants" />
              </Box>
              <Box
                sx={{
                  gap: "20px",
                  display: "flex",
                }}
              >
                <SFormControlLabel
                  control={<SCheckbox defaultChecked />}
                  label="Miles"
                />
                <SFormControlLabel
                  control={<SCheckbox color="success" />}
                  label="Cashback"
                />
              </Box>
              <Box
                sx={{
                  gap: "20px",
                  display: "flex",
                }}
              >
                <WrapperSelect>
                  <Select
                    value={cateogryMerchant}
                    fullWidth
                    onChange={handleChange}
                    displayEmpty
                    size="small"
                    sx={{
                      borderRadius: "10px",
                      "& fieldset": {
                        border: "1px solid rgba(109, 125, 147, 0.15)",
                      },
                    }}
                    renderValue={
                      cateogryMerchant !== ""
                        ? () => <Placeholder>Top Offers</Placeholder>
                        : () => <Placeholder>Top Offers</Placeholder>
                    }
                  >
                    <MenuItem value={"top-offers"}>Top Offers</MenuItem>
                  </Select>
                </WrapperSelect>
                <WrapperSelect>
                  <Select
                    value={alphabet}
                    fullWidth
                    onChange={handleChange}
                    displayEmpty
                    size="small"
                    sx={{
                      borderRadius: "10px",
                      "& fieldset": {
                        border: "1px solid rgba(109, 125, 147, 0.15)",
                      },
                    }}
                    renderValue={
                      alphabet !== ""
                        ? undefined
                        : () => <Placeholder>A-Z</Placeholder>
                    }
                  >
                    <MenuItem value={"a-z"}>A-Z</MenuItem>
                  </Select>
                </WrapperSelect>
              </Box>
            </WrapperFilter>
            <WrapperCashMile>{/* <SwitchButton /> */}</WrapperCashMile>
            <WrapperGrid>
              <Grid container spacing={4}>
                {Array.isArray(merchants) &&
                  favoriteMerchants.map((merchant, index) => (
                    <Grid item xs={12} md={6} sm={12} lg={4} xl={4} key={index}>
                      <div onClick={() => handleClickMerchant(merchant._id)}>
                        <CardMerchant
                          logo={merchant.logoUrl}
                          name={merchant.name}
                          kpoint={merchant.discount}
                          cashback={merchant.cashback}
                          isSaved
                        />
                      </div>
                    </Grid>
                  ))}
              </Grid>
            </WrapperGrid>
          </WrapperCategoryContent>
        </CustomTabPanel>
      </WrapperMainContent>
      <Dialog
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(10px)",
            boxShadow: "0px 0px 10px 5px rgba(34, 41, 70, 0.50)",
          },
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              borderRadius: "50px",
              minHeight: "600px",
              maxWidth: "1100px",
            },
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <SDialogContent
          style={{
            justifyContent: "center",
            display: "flex",
            backgroundColor: "#D0D7E8",
          }}
          sx={{
            padding: { xs: "32px", md: "64px" },
          }}
        >
          <MerchantDetailComponent
            data={merchantDetail}
            openAddCard={() => {
              setOpen(false);
              setOpenForm(true);
            }}
            handleSuccess={handleOpenToast}
          />
        </SDialogContent>
      </Dialog>
      <Snackbar
        open={openToast}
        autoHideDuration={2000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseToast}
          severity="success"
          sx={{
            width: "100%",
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                minHeight: "0 !important",
              },
            },
          }}
        >
          Saved successfully!
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const WrapperMainContent = styled.div`
  width: 100%;
`;

const Welcome = styled.h1`
  color: #f5f5f5;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: "DM Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 133.333% */
  letter-spacing: -0.72px;
  margin: 0;
  margin-bottom: 30px;

  @media (max-width: 900px) {
    font-size: 24px;
  }
`;

const WrapperChat = styled(Box)`
  border-radius: 12px;
  background: ${(props) => props.theme.colors.primary};
  box-shadow: 4px 4px 20px -10px rgba(0, 0, 0, 0.1);
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 32px;
  margin: auto;
  margin-bottom: 30px;
  box-sizing: border-box;
  position: relative;
`;

const ChatTitle = styled.p`
  margin: 0;
  color: #fff;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
  margin-bottom: 8px;
`;

const ChatSubTitle = styled.p`
  color: #fff;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  letter-spacing: 0.4px;
  margin: 0;
`;

const RobotIcon = styled.img`
  position: absolute;
  bottom: 0;
  right: 25%;
`;

const ArrowRightIcon = styled.img``;

const WrapperStartChat = styled.div`
  height: 100%;
`;

const StartChat = styled.div`
  border-radius: 50px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.text};
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 4px 32px;
  cursor: pointer;
`;

const CategoryTitle = styled.h3`
  color: #000;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
  margin-bottom: 24px;
`;

const WrapperFilter = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const WrapperSelect = styled.div`
  min-width: 120px;
`;

const Placeholder = styled.p`
  margin: 0;
  color: var(--web-greyyy, #6d7d93);
  font-family: DM Sans;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const WrapperCategoryContent = styled.div`
  border-radius: 0 12px 12px;
  background: ${(props) => props.theme.colors.background};
  box-shadow: 4px 4px 20px -10px rgba(0, 0, 0, 0.1);
  padding: 16px 32px 32px;
  box-sizing: border-box;
`;

const WrapperCashMile = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 24px 0 36px 0;
  background: rgba(109, 125, 147, 0.5);
  height: 0.5px;
`;

const WrapperGrid = styled.div`
  height: 400px;
  overflow: auto;
  padding-right: 16px;
  margin-top: 8px;

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(109, 125, 147, 0.5);
  }
`;

const SDialogContent = styled(DialogContent)`
  min-height: unset !important;

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
  }

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c1c1c1;
  }
`;

const SFormControlLabel = styled(FormControlLabel)({
  "&.MuiFormControlLabel-root": {
    margin: 0,
    color: "#6D7D93",
    ".MuiTypography-root": {
      fontFamily: "Poppins",
    },
  },
});

const SCheckbox = styled(Checkbox)`
  padding: 0 !important;
  padding-right: 8px !important;
`;

export default AppsComponent;
