import styled from "styled-components";
import React from "react";
import { CircularProgress, extendTheme } from "@mui/joy";
import { Typography } from "@mui/material";
import chevronRightIcon from "../../assets/chevron_right.svg";

interface ISavingCard {
  value: number;
  name: string;
  subTitle: string;
  color: string;
}

const SavingCard = ({ value, name, subTitle, color }: ISavingCard) => {
  return (
    <Wrapper>
      <CircularProgress determinate value={value as number}>
        <STypography>{value}%</STypography>
      </CircularProgress>
      <WrapperInfo>
        <Name>{name}</Name>
        <SubTitle>{subTitle}</SubTitle>
      </WrapperInfo>
      <ArrowRight src={chevronRightIcon} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-radius: 6px;
  border: 0.5px solid rgba(109, 125, 147, 0.15);
  background: #fff;
  box-shadow: 4px 4px 20px -10px rgba(0, 0, 0, 0.1);
  width: 100%;
  display: flex;
  padding: 12px 18px;
  box-sizing: border-box;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

// const SCircularProgress = styled(CircularProgress)<{ customColor: string }>`
//   && {
//     stroke: ${({ customColor }) => customColor || "#2196f3"} !important;
//   }
// `;

const STypography = styled(Typography)`
  color: #000;
  font-family: DM Sans !important;
  font-size: 10px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.2px;
`;

const WrapperInfo = styled.div`
  margin-left: 16px;
`;

const Name = styled.p`
  margin: 0;
  color: #000;
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
`;

const SubTitle = styled.p`
  margin: 0;
  color: var(--web-greyyy, #6d7d93);
  font-family: DM Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const ArrowRight = styled.img`
  position: absolute;
  right: 18px;
`;

export default SavingCard;
