import { useContext } from "react";
import styled from "styled-components";
import logo from "../../assets/logo.png";
import { ReactComponent as AppsIcon } from "../../assets/apps.svg";
import { ReactComponent as WalletIcon } from "../../assets/wallet.svg";
import { ReactComponent as RobotIcon } from "../../assets/robot.svg";
import { ReactComponent as LogoutIcon } from "../../assets/logout.svg";
import { ReactComponent as AdminIcon } from "../../assets/admin.svg";
import { ReactComponent as PaymentIcon } from "../../assets/payment.svg";
import { useAppDispatch, useAppSelector } from "../../store";
import { logout } from "../../store/actions/auth.action";
import {
  AuthStepContext,
  AuthStepContextType,
  AuthStepEnum,
} from "../../context/authStep";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";

const SidebarMenu = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { handleSetStep } = useContext(AuthStepContext) as AuthStepContextType;
  const currentPath = window.location.pathname.replace(/\//g, "");
  const authState = useAppSelector((state) => state.auth);

  const handleLogout = async () => {
    try {
      if (authState.me) {
        await dispatch(logout());
      } else {
        handleSetStep(AuthStepEnum.LOGIN);
      }
    } catch (exception: any) {
      console.log(exception);
    }
  };

  return (
    <Wrapper>
      <Logo src={logo} />
      <ListMenuItems>
        <Tooltip title="Main Dashboard" arrow placement="right">
          <WrapperIcon actived={currentPath === "" ? true : false}>
            <AppsIcon
              fill={currentPath === "" ? "#fff" : "#ffffff66"}
              onClick={() => navigate("/")}
            />
          </WrapperIcon>
        </Tooltip>

        {authState.me && (
          <>
            <Tooltip title="Card Management" arrow placement="right">
              <WrapperIcon actived={currentPath === "wallet" ? true : false}>
                <WalletIcon
                  fill={currentPath === "wallet" ? "#fff" : "#ffffff66"}
                  onClick={() => navigate("/wallet")}
                />
              </WrapperIcon>
            </Tooltip>
            <Tooltip title="AI Assistant" arrow placement="right">
              <WrapperIcon actived={currentPath === "chat-bot" ? true : false}>
                <RobotIcon
                  opacity={currentPath === "chat-bot" ? "1" : "0.4"}
                  onClick={() => navigate("/chat-bot")}
                />
              </WrapperIcon>
            </Tooltip>
            <Tooltip title="Subscription" arrow placement="right">
              <WrapperIcon actived={currentPath === "payment" ? true : false}>
                <PaymentIcon
                  opacity={currentPath === "payment" ? "1" : "0.4"}
                  onClick={() => navigate("/payment")}
                />
              </WrapperIcon>
            </Tooltip>
          </>
        )}
        {authState.me?.isAdmin && (
          <div>
            <Tooltip title="Admin" arrow placement="right">
              <WrapperIcon>
                <AdminIcon
                  onClick={async () => {
                    navigate("/admin-merchant");
                  }}
                />
              </WrapperIcon>
            </Tooltip>
          </div>
        )}
      </ListMenuItems>
      <ListActions>
        <Tooltip title="Logout" arrow placement="right">
          <WrapperIcon>
            <LogoutIcon fill="#2E2E2E" onClick={handleLogout} />
          </WrapperIcon>
        </Tooltip>
      </ListActions>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  box-sizing: border-box;
`;

const Logo = styled.img`
  width: 80%;
  height: fit-content;
  margin-bottom: 70px;
`;

const WrapperIcon = styled.div<{ actived?: boolean }>`
  display: flex;
  cursor: pointer;
  border-left: ${(props) => (props.actived ? "2px solid #fff" : "")};
  width: 100%;
  justify-content: center;
  box-sizing: border-box;

  svg {
    max-height: 25px;
  }
`;

const ListMenuItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
  width: 100%;
  box-sizing: border-box;
`;

const ListActions = styled.div`
  gap: 40px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 50px;
`;

export default SidebarMenu;
