import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {
  AuthStepContext,
  AuthStepContextType,
  AuthStepEnum,
} from "../../context/authStep";
import { useAppDispatch, useAppSelector } from "../../store";
import notificationIcon from "../../assets/bell.svg";
import chatIcon from "../../assets/chat.svg";
import arrowDown from "../../assets/arrow_down.svg";
import addIcon from "../../assets/add.svg";
import cardsImg from "../../assets/Card.png";
import SavingCard from "../SavingCard";
import { Box, Dialog, DialogContent } from "@mui/material";
import FormAddCard from "../FormAddCard";
import avatar from "../../assets/avatar.png";
import noCreditCardImg from "../../assets/no_card_selected.svg";
import { getAllUserCardsThunk } from "../../store/actions/creditCard.action";

const savingCards = [
  {
    value: 60,
    name: "Food & Dining",
    subTitle: "1 month later",
  },
  {
    value: 70,
    name: "Retail & Shopping",
    subTitle: "1 month later",
  },
  {
    value: 43,
    name: "Travel & Transportation",
    subTitle: "1 month later",
  },
  {
    value: 10,
    name: "Technology & electronics",
    subTitle: "1 month later",
  },
];

const NoCardAdded = () => {
  return (
    <WrapperNoCreditCard>
      <NoAddedCreditCardImage src={noCreditCardImg} />
      <NoAddedCreditCardTitle>
        Your Card Collection Awaits!
      </NoAddedCreditCardTitle>
      <NoAddedCreditCardSubtitle>
        Start earning rewards by <br /> adding new cards.
      </NoAddedCreditCardSubtitle>
    </WrapperNoCreditCard>
  );
};

const RightBar = () => {
  const { handleSetStep } = useContext(AuthStepContext) as AuthStepContextType;
  const authState = useAppSelector((state) => state.auth);
  const { allUserCards } = useAppSelector((state) => state.creditCard);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const fetchData = async () => {
    try {
      await dispatch(getAllUserCardsThunk()).unwrap();
    } catch (e) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  return (
    <>
      <WrapperRightBar>
        <WrapperUser
          sx={{
            display: { xs: "none", md: "flex" },
          }}
        >
          {authState.me ? (
            <WrapperUserInfo>
              <NotificationIcon src={notificationIcon} />
              <ChatIcon src={chatIcon} />
              <WrapperAvatar>
                <Avatar src={avatar} />
                <ArrowDown src={arrowDown} />
              </WrapperAvatar>
            </WrapperUserInfo>
          ) : (
            <>
              <LoginBtn onClick={() => handleSetStep(AuthStepEnum.LOGIN)}>
                Login
              </LoginBtn>
              <JoinNowBtn onClick={() => handleSetStep(AuthStepEnum.REGISTER)}>
                Join Now!
              </JoinNowBtn>
            </>
          )}
        </WrapperUser>
        <WrapperRightBarContainer>
          {authState.me ? (
            <TitleRightBarAuthContainer>My Cards</TitleRightBarAuthContainer>
          ) : (
            <TitleRightBarContainer>Maximize Your Cards</TitleRightBarContainer>
          )}
          {allUserCards.length !== 0 ? (
            <Cards src={cardsImg} />
          ) : (
            <NoCardAdded />
          )}
          {authState.me ? (
            <AddNewCard onClick={() => setOpenForm(true)}>
              <AddIcon src={addIcon} /> Add New Card
            </AddNewCard>
          ) : (
            <EndTitleCardsContainer>
              Explore top cards and track your benefits effortlessly!
            </EndTitleCardsContainer>
          )}
        </WrapperRightBarContainer>
        <WrapperRightBarContainer
          sx={{
            display: { xs: "none", md: "flex" },
          }}
        >
          {authState.me ? (
            <TitleRightBarAuthContainer>Savings</TitleRightBarAuthContainer>
          ) : (
            <TitleRightBarContainer>Visualize Your Wins</TitleRightBarContainer>
          )}
          {savingCards.map((item, index) => (
            <WrapperSavingCards>
              <SavingCard
                key={index}
                value={item.value}
                name={item.name}
                subTitle={item.subTitle}
                color="#037BCB"
              />
            </WrapperSavingCards>
          ))}
        </WrapperRightBarContainer>
      </WrapperRightBar>
      <Dialog
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(10px)",
            boxShadow: "0px 0px 10px 5px rgba(34, 41, 70, 0.50)",
          },
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              borderRadius: "50px",
              maxWidth: "800px",
            },
            "& .MuiDialogContent-root": {
              minHeight: "500px",
            },
          },
        }}
        open={openForm}
        onClose={handleCloseForm}
      >
        <SDialogContent
          style={{
            justifyContent: "center",
            display: "flex",
            backgroundColor: '#D0D7E8'
          }}
          sx={{
            padding: { xs: "32px", md: "64px" },
          }}
        >
          <FormAddCard />
        </SDialogContent>
      </Dialog>
    </>
  );
};

const WrapperRightBar = styled.div`
  width: 100%;
  gap: 42px;
  display: flex;
  flex-direction: column;
`;
const WrapperUser = styled(Box)`
  gap: 8px;
`;

const LoginBtn = styled.button`
  border-radius: 6px;
  border: 1px solid #e9e9e9;
  background: var(--light-light-1, #fff);
  color: var(--dark-dark-2, #586166);
  font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  padding: 12px 0;
  width: 100%;
  cursor: pointer;

  :hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
`;

const JoinNowBtn = styled.button`
  border-radius: 6px;
  background: #007fff;
  border: none;
  font-family: Outfit;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  padding: 12px 0;
  width: 100%;
  cursor: pointer;

  :hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
`;

const WrapperRightBarContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: ${props => props.theme.colors.background};
  box-shadow: 4px 4px 20px -10px rgba(0, 0, 0, 0.1);
  padding: 24px;
  align-items: center;

  @media (max-width: 900px) {
    margin-top: 20px;
  }
`;

const TitleRightBarContainer = styled.h3`
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  margin-bottom: 24px;
`;

const TitleRightBarAuthContainer = styled.h3`
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  margin-bottom: 24px;
  width: 100%;
`;

const Cards = styled.img`
  width: 100%;
  margin-bottom: 12px;

  @media (max-width: 900px) {
    width: 50%;
  }
`;

const EndTitleCardsContainer = styled.h3`
  color: var(--web-greyyy, #6d7d93);
  text-align: center;
  font-family: DM Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 70%;
  margin: 0;
`;

const AddNewCard = styled.button`
  border-radius: 6px;
  border: 1px solid #e9e9e9;
  background: linear-gradient(60deg, #3B5998 13.4%, #4A68A7 86.6%);
  font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  padding: 12px 0;
  width: 80%;
  cursor: pointer;
  color: #fff;
`;

const AddIcon = styled.img``;

const WrapperSavingCards = styled.div`
  margin-bottom: 8px;
  width: 100%;
`;

const NotificationIcon = styled.img``;

const ChatIcon = styled.img``;

const Avatar = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
`;

const WrapperUserInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 32px;
  width: 100%;
`;

const ArrowDown = styled.img`
  cursor: pointer;
`;

const WrapperAvatar = styled.div`
  display: flex;
  gap: 4px;
`;

const SDialogContent = styled(DialogContent)`
  min-height: unset !important;

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
  }

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c1c1c1;
  }
`;

const WrapperNoCreditCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 25px;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

const NoAddedCreditCardImage = styled.img`
  margin-bottom: 8px;
`;

const NoAddedCreditCardTitle = styled.p`
  margin: 0;
  color: #4a4a4a;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const NoAddedCreditCardSubtitle = styled.p`
  margin: 0;
  color: #4a4a4a;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export default RightBar;
