/* eslint-disable max-len */
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { CREATE_SUBSCRIPTION, GET_PAYMENT_STATUS } from "./types";
import SubscriptionService from "../../services/subscription.service";

export const createSubscriptionThunk = createAsyncThunk(
  CREATE_SUBSCRIPTION,
  async (
    payload: {
      paymentMode: string;
      paymentMethod: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await SubscriptionService.createSubscription(
        payload.paymentMode,
        payload.paymentMethod
      );

      return response.data.data;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const getPaymentStatusThunk = createAsyncThunk(
  GET_PAYMENT_STATUS,
  async (
    payload: {
      paymentId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await SubscriptionService.getPaymentStatus(
        payload.paymentId
      );

      return response.data.data;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);
