import { createReducer } from '@reduxjs/toolkit';
import { getAuthUser, setRfToken } from '../../core/utils/local-storage';
import { ProfileDto } from '../../models/user.model';
import { removeState, saveAT } from '../../services/localStorage.service';
import { getUserInfoThunk, loginThunk, logout, verifyCode } from '../actions/auth.action';

export interface AuthState {
  authenticated: boolean;
  loading: boolean;
  accessToken?: string;
  refreshToken?: string;
  me?: ProfileDto;
  email: string;
  password: string;
  userPaymentMode: number 
}

const initialState: AuthState = {
  authenticated: false,
  loading: false,
  me: getAuthUser(),
  email: '',
  password: '',
  userPaymentMode: 1,
};

const AuthReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(logout, (state) => {
      state.authenticated = false;
      state.me = undefined;
      delete state.accessToken;
      delete state.refreshToken;
      removeState();
    })
    .addCase(verifyCode.pending, (state) => {
      state.loading = true;
    })
    .addCase(verifyCode.fulfilled, (state, action) => {
      state.loading = false;
      state.authenticated = true;
      saveAT(action.payload.token);
      state.me = getAuthUser();
    })
    .addCase(verifyCode.rejected, (state) => {
      state.loading = false;
      state.authenticated = false;
    })
    .addCase(loginThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(loginThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.email = action.payload.email;
      state.password = action.payload.password;
      saveAT(action.payload.token);
      setRfToken(action.payload.refreshToken)
      state.me = getAuthUser();
    })
    .addCase(loginThunk.rejected, (state) => {
      state.loading = false;
      state.email = '';
      state.password = '';
    })
    .addCase(getUserInfoThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(getUserInfoThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.userPaymentMode = action.payload.userData.paymentMode
    })
    .addCase(getUserInfoThunk.rejected, (state) => {
      state.loading = false;
    })
});

export default AuthReducer;
