/* eslint-disable max-len */
import axios, { AxiosResponse } from "axios";
import { IPaymentStatus, ISubscription } from "../models/subscription";

const SubscriptionService = {
  createSubscription(paymentMode: string, paymentMethod: string): Promise<AxiosResponse<{ data: ISubscription }>> {
    return axios.post("/payment/create-subscription", { paymentMode, paymentMethod });
  },
  getPaymentStatus(paymentId: string): Promise<AxiosResponse<{ data: IPaymentStatus }>> {
    return axios.get(`/payment/${paymentId}`);
  },
};

export default SubscriptionService;
