import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../store";
import { loginThunk, verifyCode } from "../../store/actions/auth.action";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import { FormHelperText } from "@mui/material";
import { AuthStepContext, AuthStepContextType, AuthStepEnum } from "../../context/authStep";

type FormValues = {
  code: string;
};

const schema = yup.object({
  code: yup.string().required("Code is required!"),
});

interface propState {
  email: string;
  password: string;
}

const VerifyCodePage = () => {
  const location = useLocation();
  const [error, setError] = React.useState<string>("");
  const [sendCodeSuccess, setSendCodeSuccess] = React.useState<boolean>(false);
  const { handleSetStep } = useContext(AuthStepContext) as AuthStepContextType;
  const authState = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch(),
    navigate = useNavigate();
  const form = useForm({
    defaultValues: {
      code: undefined,
    },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, formState } = form;
  const { errors } = formState;

  const onSubmit = async (data: FormValues) => {
    try {
      await dispatch(
        verifyCode({
          email: authState.email,
          code: data.code,
        })
      ).unwrap();
      handleSetStep(AuthStepEnum.SUCCESS);
    } catch (exception: any) {
      setError(exception.message);
    }
  };

  const handleBack = async () => {
    navigate("/login", { replace: true });
  };

  const handleResendCode = async () => {
    try {
      await dispatch(
        loginThunk({
          email: authState.email,
          password: authState.password,
        })
      ).unwrap();
      setSendCodeSuccess(true);
    } catch (exception: any) {
      // setError(exception.message);
    }
  };

  return (
    <Wrapper>
      <Title>Verify Your Email</Title>
      <SubTitle>
        Please enter the 6-digit code sent to{" "}
        {authState.email.replace(/(\w{1})[\w.-]+@([\w.]+\w{1})/, "$1****@$2")}
      </SubTitle>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <WrapperInput>
          <WrapperInputTitle>
            <InputTitle>Code</InputTitle>
          </WrapperInputTitle>
          <TextField
            type="text"
            fullWidth
            inputProps={{
              sx: {
                padding: "12px",
              },
            }}
            {...register("code")}
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
            placeholder="Enter code here"
            error={!!errors.code}
            helperText={<FormHelperText style={{ fontFamily: 'Outfit', margin: '0' }}>{errors.code?.message}</FormHelperText>}
          />
        </WrapperInput>
        {error && <ErrorText>{error}</ErrorText>}
        {sendCodeSuccess && <SuccessText>Please check your email!</SuccessText>}
        <WrapperButton>
          <SubmitButton type="submit">Submit</SubmitButton>
          <BackButton onClick={handleBack}>Back</BackButton>
        </WrapperButton>
      </Form>
      <WrapperEndtitle>
        <SubTitle>Didn't receive code?</SubTitle>
        <ResendCode onClick={handleResendCode}>Resend code</ResendCode>
      </WrapperEndtitle>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  overflow: auto;
`;

const Title = styled.h1`
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  margin-bottom: 8px;
  font-family: Outfit;
`;

const SubTitle = styled.p`
  color: #6c6c6c;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  font-family: Outfit;
`;

const ErrorText = styled.p`
  color: red;
  margin-top: 0;
  font-family: Outfit;
`;

const SuccessText = styled.p`
  color: #4caf50;
  margin-top: 0;
  font-family: Outfit;
`;

const WrapperInput = styled.div`
  margin-bottom: 8px;
  width: 100%;
  margin-top: 32px;
`;

const WrapperButton = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  flex-direction: column;
  margin-top: 16px;
`;

const SubmitButton = styled.button`
  border-radius: 10px;
  background: #007fff;
  border: none;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 0;
  cursor: pointer;
  font-family: Outfit;

  :hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
`;

const BackButton = styled.button`
  border-radius: 10px;
  border: 1px solid #6c6c6c;
  background: #fff;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 0;
  cursor: pointer;
  width: 100%;
  color: #000;
  font-family: Outfit;

  :hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
`;

const WrapperEndtitle = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  font-size: 16px;
  justify-content: center;
`;

const ResendCode = styled.a`
  color: #007fff;
  font-style: normal;
  font-weight: 700;
  text-decoration-line: underline;
  margin-left: 4px;
  cursor: pointer;
  font-family: Outfit;
`;

const Form = styled.form``;

const WrapperInputTitle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`;

const InputTitle = styled.p`
  color: rgba(0, 0, 0, 0.93);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  font-family: Outfit;
`;

export default VerifyCodePage;
