import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import {
  Alert,
  Button,
  FormHelperText,
  LinearProgress,
  MenuItem,
  Select,
} from "@mui/material";
import { FC, useState } from "react";
import { TypeDto } from "../../models/transaction_type.model";
import { useAppDispatch } from "../../store";
import {
  createTypesThunk,
  updateTypeThunk,
} from "../../store/actions/transaction_type";

type FormValues = {
  id: string;
  name: string;
};

const schema = yup.object({
  name: yup.string().required("Name is required!"),
});

interface IFormType {
  data?: FormValues;
  refetchData?: () => void;
  handleSuccess?: () => void;
}

const FormType: FC<IFormType> = ({
  data = undefined,
  refetchData,
  handleSuccess,
}) => {
  const form = useForm<FormValues>({
    defaultValues: data
      ? { name: data.name }
      : {
          name: "",
        },
    resolver: yupResolver(schema) as any,
  });
  const { register, handleSubmit, formState, getValues } = form;
  const { errors } = formState;
  const dispatch = useAppDispatch();

  const onSubmit = async (dataForm: FormValues) => {
    if (data) {
      await dispatch(
        updateTypeThunk({ id: data.id, name: dataForm.name })
      ).unwrap();
    } else {
      await dispatch(createTypesThunk({ name: dataForm.name })).unwrap();
    }
    refetchData && refetchData();
    handleSuccess && handleSuccess();
  };

  return (
    <Wrapper>
      <Title>
        {data ? "Edit transaction type" : "Add new transaction type"}
      </Title>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <WrapperInput>
          <WrapperInputTitle>
            <InputTitle>Name</InputTitle>
          </WrapperInputTitle>
          <TextField
            type="text"
            fullWidth
            placeholder="Enter transaction type here"
            inputProps={{
              sx: {
                padding: "12px",
              },
            }}
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
            {...register("name")}
            error={!!errors.name}
            helperText={
              <FormHelperText style={{ fontFamily: "Outfit", margin: "0" }}>
                {errors.name?.message}
              </FormHelperText>
            }
          />
        </WrapperInput>
        <WrapperButton>
          <SubmitButton type="submit">Submit</SubmitButton>
        </WrapperButton>
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  margin: 0;
  font-family: Poppins;
`;

const WrapperInput = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const WrapperInputTitle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
`;

const InputTitle = styled.p`
  color: rgba(0, 0, 0, 0.93);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  font-family: Outfit;
`;

const ErrorText = styled.p`
  color: red;
  margin-top: 0;
`;

const WrapperButton = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  flex-direction: column;
  margin-top: 16px;
`;

const SubmitButton = styled.button`
  border-radius: 10px;
  background: #007fff;
  border: none;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 0;
  margin-top: 32px;
  width: 100%;
  cursor: pointer;
  font-family: Outfit;

  :hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
`;

const Form = styled.form`
  width: 100%;
`;

const ErrorImageMessage = styled.p`
  color: #d32f2f;
  font-family: Outfit;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  font-weight: 400;
  font-size: 0.75rem;
`;

export default FormType;
