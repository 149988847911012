import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled from "styled-components";
import {
  Alert,
  Dialog,
  DialogContent,
  Snackbar,
  TablePagination,
} from "@mui/material";
import settingIcon from "../../assets/setting.svg";
import deleteIcon from "../../assets/delete.svg";
import FormMerchants from "./form";
import { MerchantDto } from "../../models/merchant.model";
import { useAppDispatch } from "../../store";
import {
  deleteMerchantThunk,
  getMerchantThunk,
  getMerchantsThunk,
} from "../../store/actions/merchant.action";
import { getTypesThunk } from "../../store/actions/transaction_type";

interface Column {
  id: "name" | "logo" | "code" | "type" | "discount" | "redirectUrl" | "action";
  label: string;
  width?: string;
  align?: "right" | "center";
  component?: React.ReactNode;
}

interface IData {
  id: string;
  name: string;
  code: number;
  logo: string;
  type: string;
  discount: number;
  redirectUrl: string;
  action: React.ReactNode;
}

const columns: readonly Column[] = [
  { id: "name", label: "Merchant Name", width: "17%" },
  { id: "code", label: "MCC", width: "10%" },
  { id: "logo", label: "Logo", width: "20%" },
  {
    id: "type",
    label: "Transaction Type",
    width: "20%",
  },
  {
    id: "discount",
    label: "Discount",
    width: "8%",
  },
  {
    id: "redirectUrl",
    label: "Redirect URL",
    width: "10%",
  },
  {
    id: "action",
    label: "Action",
    width: "15%",
    align: "right",
  },
];

const MerchantAdmin = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openAddForm, setOpenAddForm] = React.useState<boolean>(false);
  const [merchant, setMerchant] = React.useState<any>();
  const [listMerchant, setListMerchants] = React.useState<IData[]>([]);
  const [listTypes, setListTypes] = React.useState<any[]>([]);
  const dispatch = useAppDispatch();
  const [openToast, setOpenToast] = React.useState<boolean>(false);

  const handleEditMerchant = async (merchantId: string) => {
    const res = await dispatch(getMerchantThunk({ id: merchantId })).unwrap();
    setMerchant({
      id: res._id,
      code: res.mccCode,
      discount: res.discount,
      logo: res.logoUrl,
      name: res.name,
      redirectUrl: res.redirectUrl,
      type: res?.transactionType?._id || '',
    });
    setOpenAddForm(true);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchListMerchants = async () => {
    try {
      const res = await dispatch(getMerchantsThunk({})).unwrap();
      const temp = res.map((item) => {
        return {
          id: item._id,
          code: item.mccCode,
          discount: item.discount,
          logo: item.logoUrl,
          name: item.name,
          redirectUrl: item.redirectUrl,
          type: item.transactionType?.name,
          action: "",
        };
      });

      setListMerchants(temp);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchListTypes = async () => {
    try {
      const res = await dispatch(getTypesThunk()).unwrap();
      const temp = res.map((item) => {
        return {
          value: item._id,
          label: item.name,
        };
      });

      setListTypes(temp);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteMerchant = async (merchantId: string) => {
    await dispatch(deleteMerchantThunk({ id: merchantId })).unwrap();
    fetchListMerchants();
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const handleOpenToast = () => {
    setOpenToast(true);
    setOpenAddForm(false);
  };

  React.useEffect(() => {
    fetchListMerchants();
    fetchListTypes();
  }, []);

  return (
    <Wrapper>
      <Title>MERCHANT</Title>
      <BtnAdd
        onClick={() => {
          setMerchant(undefined);
          setOpenAddForm(true);
        }}
      >
        Add Merchant
      </BtnAdd>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    align={column.align}
                    style={{
                      width: column.width,
                      fontWeight: "bold",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {listMerchant
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((merchant, rIndex) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={rIndex}>
                      {columns.map((column, index) => {
                        const value = merchant[column.id];
                        return (
                          <TableCell key={index} align={column.align}>
                            {column.id === "action" ? (
                              <WrapperActions>
                                <Icon
                                  src={settingIcon}
                                  onClick={() =>
                                    handleEditMerchant(merchant.id)
                                  }
                                />
                                <Icon
                                  src={deleteIcon}
                                  onClick={() => handleDeleteMerchant(merchant.id)}
                                />
                              </WrapperActions>
                            ) : column.id === "logo" ? (
                              <img
                                style={{ width: "80%" }}
                                src={merchant.logo}
                                alt=""
                              />
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={listMerchant.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Dialog
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(10px)",
            boxShadow: "0px 0px 10px 5px rgba(34, 41, 70, 0.50)",
          },
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              borderRadius: "50px",
              minHeight: "600px",
              maxWidth: "800px",
              padding: "64px",
              boxSizing: "border-box"
            },
          },
        }}
        open={openAddForm}
        onClose={() => {
          setOpenAddForm(false);
        }}
      >
        <SDialogContent
          style={{
            
            justifyContent: "center",
            display: "flex",
          }}
        >
          <FormMerchants
            data={merchant}
            listTypes={listTypes}
            handleSuccess={handleOpenToast}
            refetchData={fetchListMerchants}
          />
        </SDialogContent>
      </Dialog>
      <Snackbar
        open={openToast}
        autoHideDuration={2000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseToast}
          severity="success"
          sx={{ width: "100%" }}
        >
          {merchant ? 'Edit Merchant Success!' : 'Add Merchant Success!'}
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-end;
`;

const Title = styled.h1`
  width: 100%;
  text-align: center;
  font-family: Poppins;
`;

const BtnAdd = styled.button`
  border: none;
  background-color: #65b741;
  color: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-family: Outfit;
  box-sizing: border-box;
  width: fit-content;
`;

const WrapperActions = styled.div`
  display: flex;
  width: fit-content;
  float: right;
  gap: 4px;
`;

const Icon = styled.img`
  width: 20px;
  cursor: pointer;
`;

const SDialogContent = styled(DialogContent)`
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
  }

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c1c1c1;
  }
`;

export default MerchantAdmin;
