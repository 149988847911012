import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAppDispatch } from "../../store";
import { forgotPassword, verifyCode } from "../../store/actions/auth.action";
import STextField from "../../components/TextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import { FormHelperText } from "@mui/material";
import {
  AuthStepContext,
  AuthStepContextType,
  AuthStepEnum,
} from "../../context/authStep";

type FormValues = {
  email: string;
};

const schema = yup.object({
  email: yup
    .string()
    .required("Email is required!")
    .email("Email format is not valid!"),
});

const ForgotPasswordPage = () => {
  const [error, setError] = React.useState<string>("");
  const [sendSuccess, setSendSuccess] = React.useState<boolean>(false);
  const { handleSetStep } = useContext(AuthStepContext) as AuthStepContextType;
  const form = useForm({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, formState, getValues } = form;
  const { errors } = formState;
  const dispatch = useAppDispatch(),
    navigate = useNavigate();

  const onSubmit = async (data: FormValues) => {
    try {
      await dispatch(
        forgotPassword({
          email: data.email,
        })
      ).unwrap();
      setSendSuccess(true);
    } catch (exception: any) {
      setError(exception.message);
    }
  };

  return (
    <Wrapper>
      {!sendSuccess ? (
        <>
          <Title>Forgot Password?</Title>
          <SubTitle>
            No worries! Please enter your email for reset instructions.
          </SubTitle>
          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <WrapperInput>
              <WrapperInputTitle>
                <InputTitle>Email</InputTitle>
              </WrapperInputTitle>
              <TextField
                type="text"
                fullWidth
                placeholder="Enter your email here"
                inputProps={{
                  sx: {
                    padding: "12px",
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                {...register("email")}
                error={!!errors.email}
                helperText={
                  <FormHelperText style={{ fontFamily: "Outfit", margin: "0" }}>
                    {errors.email?.message}
                  </FormHelperText>
                }
              />
            </WrapperInput>
            {error && <ErrorText>{error}</ErrorText>}
            <WrapperButton>
              <SubmitButton type="submit">Reset Password</SubmitButton>
              <BackButton onClick={() => handleSetStep(AuthStepEnum.LOGIN)}>
                Back
              </BackButton>
            </WrapperButton>
          </Form>
        </>
      ) : (
        <WrapperSendSuccess>
          <Title>Check your email</Title>
          <SubTitle>
            We sent an email to{" "}
            {getValues("email").replace(
              /(\w{1})[\w.-]+@([\w.]+\w{1})/,
              "$1****@$2"
            )}{" "}
            <br />
            Please follow the instructions sent in the email to reset your
            password.
          </SubTitle>
          <Login onClick={() => handleSetStep(AuthStepEnum.LOGIN)}>
            Back to sign in
          </Login>
        </WrapperSendSuccess>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  overflow: auto;
`;

const Title = styled.h1`
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  margin-bottom: 8px;
  font-family: Outfit;
  @media (max-width: 900px) {
    font-size: 36px;
  }
`;

const WrapperInputTitle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`;

const InputTitle = styled.p`
  color: rgba(0, 0, 0, 0.93);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  font-family: Outfit;
`;

const SubTitle = styled.p`
  color: #6c6c6c;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  font-family: Outfit;
`;

const ErrorText = styled.p`
  color: red;
  margin-top: 0;
`;

const Form = styled.form``;

const WrapperInput = styled.div`
  margin-bottom: 8px;
  width: 100%;
  margin-top: 32px;
`;

const WrapperButton = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  flex-direction: column;
  margin-top: 16px;
`;

const SubmitButton = styled.button`
  border-radius: 10px;
  background: #007fff;
  border: none;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 0;
  cursor: pointer;
  font-family: Outfit;

  :hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
`;

const BackButton = styled.button`
  border-radius: 10px;
  border: 1px solid #6c6c6c;
  background: #fff;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 0;
  cursor: pointer;
  width: 100%;
  color: #000;
  font-family: Outfit;

  :hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
`;

const WrapperSendSuccess = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const Login = styled.p`
  color: #007fff;
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-decoration-line: underline;
  margin-left: 4px;
  cursor: pointer;
  margin-top: 12px;
  font-family: Outfit;
`;

export default ForgotPasswordPage;
