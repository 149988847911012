import {configureStore} from '@reduxjs/toolkit';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import { configAxios } from '../services/axios.config';
import { loadState } from '../services/localStorage.service';
import rootReducer from './reducers';

const persistedState = loadState();

const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
});

export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

type DispatchFunc = () => AppDispatch
export const useAppDispatch: DispatchFunc = useDispatch
// export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;


configAxios(store);
export default store;
