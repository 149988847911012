// Login
export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER'
export const VERIFY_CODE = 'VERIFY_CODE'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const GET_USER_INFO = 'GET_USER_INFO'
export const LOGOUT = 'LOGOUT';

export const GET_PROFILE = 'GET_PROFILE';
export const GET_LOGINED_USER_PROFILE = 'GET_LOGINED_USER_PROFILE';
export const UPDATE_LOGINED_USER_PROFILE = 'UPDATE_LOGINED_USER_PROFILE';

export const CREATE_USER_CARD = 'CREATE_USER_CARD';
export const CREATE_CARD = 'CREATE_CARD';
export const USED_CARD = 'USED_CARD';
export const UN_USED_CARD = 'UN_USED_CARD';
export const GET_CARDS = 'GET_CARDS';
export const GET_CARD = 'GET_CARD';
export const DELETE_CARD = 'DELETE_CARD';
export const REMOVE_USER_CARD = 'REMOVE_USER_CARD';
export const UPDATE_CARD = 'UPDATE_CARD';
export const ALL_USED_CARD = 'ALL_USED_CARD';

export const GET_MERCHANT = 'GET_MERCHANT';
export const GET_MERCHANTS = 'GET_MERCHANTS';
export const GET_FAVORITE_MERCHANTS = 'GET_FAVORITE_MERCHANTS';
export const ADD_FAVORITE_MERCHANTS = 'ADD_FAVORITE_MERCHANTS';
export const CREATE_MERCHANT = 'CREATE_MERCHANT';
export const DELETE_MERCHANT = 'DELETE_MERCHANT';
export const UPDATE_MERCHANT = 'UPDATE_MERCHANT';

export const GET_TYPES = 'GET_TYPES';
export const GET_TYPE = 'GET_TYPE';
export const CREATE_TYPE = 'CREATE_TYPE';
export const DELETE_TYPE = 'DELETE_TYPE';

export const CREATE_QUESTION = 'CREATE_QUESTION';
export const GET_HISTORY = 'GET_HISTORY';
export const GET_ANSWER = 'GET_ANSWER';

export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const GET_PAYMENT_STATUS = 'GET_PAYMENT_STATUS';

export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
