import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import SidebarMenu from "../../components/SidebarMenu";
import styled from "styled-components";
import { Box, Dialog, Drawer, IconButton } from "@mui/material";
import { DialogContent } from "@mui/joy";
import AuthSteps from "../../constants/auth_step";
import {
  AuthStepContext,
  AuthStepContextType,
  AuthStepEnum,
} from "../../context/authStep";
import { useAppSelector } from "../../store";
import RightBar from "../../components/RightBar";
import { isMobile } from "../../core/utils/isMobile";
import MenuIcon from "@mui/icons-material/Menu";

const drawerWidth = "90px";

const MainLayout = () => {
  const { step, handleSetStep } = React.useContext(
    AuthStepContext
  ) as AuthStepContextType;
  const [open, setOpen] = React.useState(true);
  const authState = useAppSelector((state) => state.auth);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClose = () => {
    handleSetStep(AuthStepEnum.SUCCESS);
    setOpen(false);
  };

  React.useEffect(() => {
    if (authState.me) {
      handleSetStep(AuthStepEnum.SUCCESS);
    }
    setOpen(true);
  }, [step, authState.me]);

  return (
    <Wrapper>
      <Box
        sx={{
          display: { xs: "none", md: "block" },
          width: { md: drawerWidth },
          flexShrink: { md: 0 },
        }}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <SidebarMenu />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: 'none',
            },
          }}
          open
        >
          <SidebarMenu />
        </Drawer>
      </Box>
      {step !== AuthStepEnum.SUCCESS && (
        <Dialog
          sx={{
            "& .MuiBackdrop-root": {
              backdropFilter: "blur(10px)",
              boxShadow: "0px 0px 10px 5px rgba(34, 41, 70, 0.50)",
            },
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                borderRadius: "50px",
                minHeight: "500px",
                maxWidth:
                  step === AuthStepEnum.GET_STARTED ? "1200px" : "800px",
              },
            },
          }}
          open={open}
          onClose={handleClose}
        >
          <SDialogContent
            style={{
              justifyContent: "center",
              display: "flex",
            }}
            sx={{
              padding: { xs: "32px", md: "64px" },
            }}
          >
            {AuthSteps.find((item) => item.step === step)?.component}
          </SDialogContent>
        </Dialog>
      )}
      <WrapperOutlet isMobile={isMobile()}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { md: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Outlet />
      </WrapperOutlet>
      {!isMobile() && (
        <WrapperRightBar>
          <RightBar />
        </WrapperRightBar>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: linear-gradient(0deg, rgba(59, 89, 152, 0.20) 0%, rgba(59, 89, 152, 0.50) 100%);
  display: flex;
  gap: 5%;
  min-height: 100vh;
`;

const WrapperOutlet = styled.div<{ isMobile: boolean }>`
  box-sizing: border-box;
  padding: ${(props) => (props.isMobile ? "20px" : "40px 0")};
  width: ${(props) => (props.isMobile ? "100%" : "70%")};
`;

const WrapperRightBar = styled.div`
  box-sizing: border-box;
  padding: 40px 40px 40px 0;
  width: 25%;
`;

const SDialogContent = styled(DialogContent)`
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
  }

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c1c1c1;
  }
`;

export default MainLayout;
