import { FC } from "react";
import styled from "styled-components";
import { ReactComponent as CheckIcon } from "../../assets/check.svg";
import { ReactComponent as MileIcon } from "../../assets/mile.svg";
import { ReactComponent as CashbackIcon } from "../../assets/cashback.svg";

interface IItem {
  getBonus: boolean;
  value: string;
}

const MileItem: FC<IItem> = ({ getBonus, value }) => {
  return (
    <Wrapper>
      {getBonus ? (
        <Mile>
          <WrapperMileIcon>
            <MileIcon />
          </WrapperMileIcon>
          {value} Miles <CheckIcon style={{ marginLeft: "4px" }} fill="#51A1FE" />
        </Mile>
      ) : (
        <NoBonus>No Mile</NoBonus>
      )}
    </Wrapper>
  );
};

const PointItem: FC<IItem> = ({ getBonus, value }) => {
  return (
    <Wrapper>
      {getBonus ? (
        <Rebate>
          <WrapperCashbackIcon>
            <CashbackIcon />
          </WrapperCashbackIcon>
          {value} Points <CheckIcon fill="#66BB6A" />
        </Rebate>
      ) : (
        <NoBonus>No Piont</NoBonus>
      )}
    </Wrapper>
  );
};

const RebateItem: FC<IItem> = ({ getBonus, value }) => {
  return (
    <Wrapper>
      {getBonus ? (
        <Rebate>
          <WrapperCashbackIcon>
            <CashbackIcon />
          </WrapperCashbackIcon>
          {value} Cashback <CheckIcon fill="#66BB6A" />
        </Rebate>
      ) : (
        <NoBonus>
          <WrapperNoBonusIcon>
            <CashbackIcon />
          </WrapperNoBonusIcon>
          No Cashback
        </NoBonus>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Mile = styled.div`
  color: #51a1fe;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
`;

const Rebate = styled.div`
  color: #66bb6a;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
`;

const NoBonus = styled.div`
  color: rgba(109, 125, 147, 0.5);
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
`;

const WrapperMileIcon = styled.div`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #51a1fe;
  margin-right: 5px;
`;

const WrapperCashbackIcon = styled.div`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #66bb6a;
  margin-right: 5px;
`;

const WrapperNoBonusIcon = styled.div`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(109, 125, 147, 0.5);
  margin-right: 5px;
`;

export {
  MileItem,
  PointItem,
  RebateItem,
};
