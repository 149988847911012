import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_TRANSACTIONS } from './types';
import axios from 'axios';
import TransactionService from '../../services/transaction.service';

export const getTransactionsThunk = createAsyncThunk(
  GET_TRANSACTIONS,
  async (
    payload: {
      fromDate: string;
      toDate: string;
      keyword?: string;
      userCardId?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await TransactionService.getTransactions(
        payload.fromDate,
        payload.toDate,
        payload.keyword,
        payload.userCardId
      );
      return res.data.data;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);
