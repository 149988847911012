import { useAppDispatch, useAppSelector } from "../../store/index";
import { useNavigate } from "react-router-dom";
import { loginThunk } from "../../store/actions/auth.action";
import { useContext, useState } from "react";
import { saveState } from "../../services/localStorage.service";
import styled from "styled-components";
import { Checkbox, FormHelperText } from "@mui/material";
import appleIcon from "../../assets/apple.svg";
import googleIcon from "../../assets/google.svg";
import facebookIcon from "../../assets/facebook.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  AuthStepContext,
  AuthStepContextType,
  AuthStepEnum,
} from "../../context/authStep";

type FormValues = {
  email: string;
  password: string;
};

const schema = yup.object({
  email: yup
    .string()
    .required("Email is required!")
    .email("Email format is not valid!"),
  password: yup.string().required("Password is required!"),
});

const LoginForm = () => {
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { handleSetStep } = useContext(AuthStepContext) as AuthStepContextType;
  const form = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, formState } = form;
  const { errors } = formState;

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const dispatch = useAppDispatch(),
    navigate = useNavigate(),
    authState = useAppSelector((state) => state.auth);

  const onSubmit = async (data: FormValues) => {
    try {
      await dispatch(
        loginThunk({
          email: data.email,
          password: data.password,
        })
      ).unwrap();
      handleSetStep(AuthStepEnum.SUCCESS);
    } catch (exception: any) {
      if (exception.message === "User is not verified!") {
        setError(
          "We have just sent you a verification email, you need to verify your account to continue!"
        );
      } else {
        setError(exception.message);
      }
    }
    saveState({ auth: authState });
  };

  return (
    <Wrapper>
      <Title>Welcome back!</Title>
      <WrapperSubTitle>
        <SubTitle>Don't have an account?</SubTitle>
        <Signup onClick={() => handleSetStep(AuthStepEnum.REGISTER)}>
          Sign Up
        </Signup>
      </WrapperSubTitle>
      <WrapperForm>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <WrapperInput>
            <WrapperInputTitle>
              <InputTitle>Email</InputTitle>
            </WrapperInputTitle>
            <TextField
              type="text"
              fullWidth
              inputProps={{
                sx: {
                  padding: "12px",
                },
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
              {...register("email")}
              error={!!errors.email}
              helperText={
                <FormHelperText style={{ fontFamily: "Outfit", margin: "0" }}>
                  {errors.email?.message}
                </FormHelperText>
              }
            />
          </WrapperInput>
          <WrapperInput>
            <WrapperInputTitle>
              <InputTitle>Password</InputTitle>
            </WrapperInputTitle>
            <TextField
              type={showPassword ? "text" : "password"}
              fullWidth
              inputProps={{
                sx: {
                  padding: "12px",
                },
              }}
              {...register("password")}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!!errors.password}
              helperText={
                <FormHelperText style={{ fontFamily: "Outfit", margin: "0" }}>
                  {errors.password?.message}
                </FormHelperText>
              }
            />
          </WrapperInput>
          <WrapperAction>
            <WrapperRememberMe>
              <SCheckbox />
              <RememberMe>Remember Me</RememberMe>
            </WrapperRememberMe>
            <ForgotPassword
              onClick={() => handleSetStep(AuthStepEnum.FORGOT_PASSWORD)}
            >
              Forgot Password?
            </ForgotPassword>
          </WrapperAction>
          {error && <Error>{error}</Error>}
          <SubmitButton type="submit">Login</SubmitButton>
        </Form>
        <MoreOptions>Or Login with</MoreOptions>
        <WrapperLoginOptions>
          <WrapperLoginIcon>
            <IconLogin src={googleIcon} alt="" />
          </WrapperLoginIcon>
          <WrapperLoginIcon>
            <IconLogin src={facebookIcon} alt="" />
          </WrapperLoginIcon>
          <WrapperLoginIcon>
            <IconLogin src={appleIcon} alt="" />
          </WrapperLoginIcon>
        </WrapperLoginOptions>
      </WrapperForm>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
`;

const Form = styled.form``;

const Title = styled.h1`
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  font-family: Outfit;
  @media (max-width: 900px) {
    font-size: 36px;
  }
`;

const WrapperSubTitle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 42px;
  margin-top: 8px;
`;

const SubTitle = styled.p`
  color: #6c6c6c;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  font-family: Outfit;
`;

const Signup = styled.p`
  color: #007fff;
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-decoration-line: underline;
  margin-left: 4px;
  cursor: pointer;
  font-family: Outfit;
`;

const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapperInput = styled.div`
  margin-bottom: 24px;
`;

const WrapperAction = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SCheckbox = styled(Checkbox)({
  "&.MuiButtonBase-root": {
    padding: 0,
  },
});

const RememberMe = styled.p`
  color: rgba(0, 0, 0, 0.59);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  margin-left: 4px;
  font-family: Outfit;
`;

const WrapperRememberMe = styled.div`
  display: flex;
`;

const ForgotPassword = styled.p`
  color: rgba(0, 0, 0, 0.59);
  margin: 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: none;
  font-family: Outfit;
  cursor: pointer;
`;

const SubmitButton = styled.button`
  border-radius: 10px;
  background: #007fff;
  border: none;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 0;
  margin-top: 32px;
  width: 100%;
  cursor: pointer;
  font-family: Outfit;

  :hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
`;

const MoreOptions = styled.p`
  color: #757171;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin: 0;
  margin-top: 31px;
  font-family: Outfit;
`;

const WrapperLoginOptions = styled.div`
  display: flex;
  gap: 22px;
  justify-content: center;
  margin-top: 31px;
`;

const WrapperLoginIcon = styled.div`
  border-radius: 50%;
  background: #f3f3f3;
  width: fit-content;
  cursor: pointer;
`;

const IconLogin = styled.img`
  width: 22px;
  height: 22px;
  padding: 22px;
`;

const Error = styled.p`
  margin: 0;
  color: #d32f2f;
  font-size: 18px;
  margin-top: 32px;
`;

const WrapperInputTitle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`;

const InputTitle = styled.p`
  color: rgba(0, 0, 0, 0.93);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  font-family: Outfit;
`;

export default LoginForm;
