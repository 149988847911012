import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled from "styled-components";
import {
  Alert,
  Dialog,
  DialogContent,
  Snackbar,
  TablePagination,
} from "@mui/material";
import settingIcon from "../../assets/setting.svg";
import deleteIcon from "../../assets/delete.svg";
import { useAppDispatch } from "../../store";
import {
  deleteTypesThunk,
  getTypesThunk,
} from "../../store/actions/transaction_type";
import {
  deleteCardThunk,
  getCardThunk,
  getCardsThunk,
} from "../../store/actions/creditCard.action";
import FormCard from "./form";
import { getCountryByCode } from "../../core/utils/get-country-by-code";

interface Column {
  id: "name" | "logo" | "point" | "mile" | "action" | "country" | "urlHomePage";
  label: string;
  width?: string;
  align?: "right" | "center";
  component?: React.ReactNode;
}

interface IData {
  id: string;
  name: string;
  country: string;
  urlHomePage: string;
  logo: string;
  point: number;
  mile: number;
  action: React.ReactNode;
}

const columns: readonly Column[] = [
  { id: "name", label: "Transaction Type Name", width: "15%" },
  { id: "logo", label: "Image", width: "20%" },
  { id: "country", label: "Country", width: "15%", align: "center" },
  { id: "urlHomePage", label: "URL", width: "20%", align: "center" },
  { id: "point", label: "Point", width: "10%", align: "center" },
  { id: "mile", label: "Mile", width: "10%", align: "center" },
  {
    id: "action",
    label: "Action",
    width: "10%",
    align: "right",
  },
];

const CardAdmin = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [listCards, setListCards] = React.useState<IData[]>([]);
  const [openAddForm, setOpenAddForm] = React.useState<boolean>(false);
  const [card, setCard] = React.useState<any>();
  const dispatch = useAppDispatch();
  const [openToast, setOpenToast] = React.useState<boolean>(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEditCard = async (cardId: string) => {
    const res = await dispatch(getCardThunk({ id: cardId })).unwrap();
    setCard({
      id: res._id,
      name: res.name,
      logo: res.logoUrl,
      defaultPoint: res.defaultPoint,
      rate: res.rate,
      country: res.countryCode,
      urlHomePage: res.urlCardHomePage,
    });
    setOpenAddForm(true);
  };

  const handleDeleteCard = async (cardId: string) => {
    await dispatch(deleteCardThunk({ id: cardId })).unwrap();
    fetchListCards();
  };

  const fetchListCards = async () => {
    try {
      const res = await dispatch(getCardsThunk()).unwrap();
      const temp = res.map((item) => {
        return {
          id: item._id,
          name: item.name,
          logo: item.logoUrl,
          point: item.defaultPoint,
          mile: item.rate,
          country: getCountryByCode(item.countryCode),
          urlHomePage: item.urlCardHomePage,
          action: "",
        };
      });

      setListCards(temp);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const handleOpenToast = () => {
    setOpenToast(true);
    setOpenAddForm(false);
  };

  React.useEffect(() => {
    fetchListCards();
  }, []);

  return (
    <Wrapper>
      <Title>CARD</Title>
      <BtnAdd
        onClick={() => {
          setCard(undefined);
          setOpenAddForm(true);
        }}
      >
        Add Card
      </BtnAdd>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    align={column.align}
                    style={{
                      width: column.width,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {listCards
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((card, rIndex) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={rIndex}>
                      {columns.map((column, index) => {
                        const value = card[column.id];
                        return (
                          <TableCell key={index} align={column.align}>
                            {column.id === "action" ? (
                              <WrapperActions>
                                <Icon
                                  src={settingIcon}
                                  onClick={() => handleEditCard(card.id)}
                                />
                                <Icon
                                  src={deleteIcon}
                                  onClick={() => handleDeleteCard(card.id)}
                                />
                              </WrapperActions>
                            ) : column.id === "logo" ? (
                              <img
                                style={{ width: "80%" }}
                                src={card.logo}
                                alt=""
                              />
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={listCards.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Dialog
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(10px)",
            boxShadow: "0px 0px 10px 5px rgba(34, 41, 70, 0.50)",
          },
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              borderRadius: "50px",
              maxWidth: "800px",
              padding: "64px",
              boxSizing: "border-box"
            },
          },
        }}
        open={openAddForm}
        onClose={() => {
          setOpenAddForm(false);
        }}
      >
        <SDialogContent
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <FormCard
            data={card}
            handleSuccess={handleOpenToast}
            refetchData={fetchListCards}
          />
        </SDialogContent>
      </Dialog>
      <Snackbar
        open={openToast}
        autoHideDuration={2000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseToast}
          severity="success"
          sx={{ width: "100%" }}
        >
          {card ? "Edit Card Success!" : "Add Card Success!"}
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-end;
`;

const Title = styled.h1`
  width: 100%;
  text-align: center;
  font-family: Poppins;
`;

const BtnAdd = styled.button`
  border: none;
  background-color: #65b741;
  color: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-family: Outfit;
  box-sizing: border-box;
  width: fit-content;
`;

const WrapperActions = styled.div`
  display: flex;
  width: fit-content;
  float: right;
  gap: 12px;
`;

const Icon = styled.img`
  width: 20px;
  cursor: pointer;
`;

const SDialogContent = styled(DialogContent)`
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
  }

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c1c1c1;
  }
`;

export default CardAdmin;
