/* eslint-disable max-len */
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  ALL_USED_CARD,
  CREATE_USER_CARD,
  DELETE_CARD,
  GET_CARD,
  GET_CARDS,
  REMOVE_USER_CARD,
  UN_USED_CARD,
  UPDATE_CARD,
  USED_CARD,
} from "./types";
import CreditCardService from "../../services/creditCard.service";

interface CardParam {
  name: string;
  image: File;
  defaultPoint: number;
  rate: number;
  countryCode: string;
  urlHomePage: string;
}

export const createUserCardThunk = createAsyncThunk(
  CREATE_USER_CARD,
  async (payload: { cardIds: string[] }, { rejectWithValue }) => {
    try {
      await CreditCardService.addUserCard(payload.cardIds);
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const getUsedCardsThunk = createAsyncThunk(
  USED_CARD,
  async (
    payload: { countryCode?: string; name?: string },
    { rejectWithValue }
  ) => {
    try {
      const { countryCode, name } = payload;
      const res = await CreditCardService.getUsedCards(countryCode || '', name || '');
      return res.data.data;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const getAllUserCardsThunk = createAsyncThunk(
  ALL_USED_CARD,
  async (
    payload,
    { rejectWithValue }
  ) => {
    try {
      const res = await CreditCardService.getUsedCards('', '');
      return res.data.data;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);


export const getUnUsedCardsThunk = createAsyncThunk(
  UN_USED_CARD,
  async (
    payload: { countryCode?: string; name?: string },
    { rejectWithValue }
  ) => {
    try {
      const { countryCode, name } = payload;
      const res = await CreditCardService.getUnUsedCards(countryCode || '', name || '');
      return res.data.data;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const getCardsThunk = createAsyncThunk(
  GET_CARDS,
  async (payload, { rejectWithValue }) => {
    try {
      const res = await CreditCardService.getCards();
      return res.data.data;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const createCardThunk = createAsyncThunk(
  CREATE_USER_CARD,
  async (payload: CardParam, { rejectWithValue }) => {
    try {
      await CreditCardService.createCard(
        payload.name,
        payload.image,
        payload.defaultPoint,
        payload.rate,
        payload.countryCode,
        payload.urlHomePage
      );
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const getCardThunk = createAsyncThunk(
  GET_CARD,
  async (payload: { id: string }, { rejectWithValue }) => {
    try {
      const res = await CreditCardService.getCard(payload.id);
      return res.data.data;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const deleteCardThunk = createAsyncThunk(
  DELETE_CARD,
  async (payload: { id: string }, { rejectWithValue }) => {
    try {
      await CreditCardService.deleteCard(payload.id);
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const removeUserCardThunk = createAsyncThunk(
  REMOVE_USER_CARD,
  async (payload: { id: string }, { rejectWithValue }) => {
    try {
      await CreditCardService.removeUserCard(payload.id);
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const updateCardThunk = createAsyncThunk(
  UPDATE_CARD,
  async (payload: { id: string; data: CardParam }, { rejectWithValue }) => {
    try {
      await CreditCardService.updateCard(payload.id, payload.data);
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);
