import AnswerComponent from "../Answer";
import RightBar from "../RightBar";
import UserQuestion from "../UserQuestion";
import styled from "styled-components";
import { ReactComponent as SendIcon } from "../../assets/send.svg";
import { ReactComponent as ReloadIcon } from "../../assets/reload.svg";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import {
  createQuestionThunk,
  getAnswerThunk,
  getHistoryThunk,
} from "../../store/actions/chat.action";
import { useEffect, useRef, useState } from "react";
import { ChatDto } from "../../models/chat.model";
import { ThreeDots } from "react-loader-spinner";

const exampleQuestions = [
  "What is the best card for ANA?",
  "What is the best credit card for staying at Hilton Hotels?",
  "What is the best card to use at where latitude 38.7453 and longitude -90.6978?",
  "What is the possible Merchandise Category Code (MCC) for Adidas?",
];

const ChatBotComponent = () => {
  const [historyMessage, setHistoryMessage] = useState<ChatDto[]>([]);
  const [question, setQuestion] = useState<string>();
  const [inputValue, setInputValue] = useState<string>();
  const [isAsking, setIsAsking] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [isSelectedExample, setIsSelectedExample] = useState(false);
  const [isShowExample, setIsShowExample] = useState(true);
  const chatBoxRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { userPaymentMode } = useAppSelector((state: RootState) => state.auth);

  const fetchHistoyMessage = async () => {
    setIsLoading(true);
    try {
      const res = await dispatch(getHistoryThunk({ page: page })).unwrap();
      setTotal(res.total);
      setPage((prevPage) => prevPage + 1);
      setHistoryMessage((prevData) => {
        const data = prevData.reverse().concat(...res.items);
        return data.reverse();
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSendMessage = async (example?: string) => {
    try {
      setIsAsking(true);
      const res = await dispatch(
        createQuestionThunk({ question: example || question || "" })
      ).unwrap();

      const temp = [...historyMessage];
      temp.push({ ...res, answer: "" });

      setHistoryMessage(temp);
      setInputValue("");

      const idInterval = setInterval(async () => {
        try {
          const data = await dispatch(
            getAnswerThunk({ id: res._id || "" })
          ).unwrap();

          if (data.answer) {
            setHistoryMessage((prevHistoryMessage) => {
              const temp = [...prevHistoryMessage];
              temp[temp.length - 1].answer = data.answer;
              return temp;
            });
            clearInterval(idInterval);
            setIsAsking(false);
            setQuestion("");
          }
        } catch (e) {
          console.log(e);
        }
      }, 3000);
    } catch (e) {
      console.log(e);
    }
  };

  const handleScroll = () => {
    if (chatBoxRef.current!.scrollTop > 0 || isLoading) {
      return;
    }
    if ((page - 1) * 10 >= total) {
      return;
    }
    chatBoxRef.current!.scrollTop = chatBoxRef.current!.clientHeight + 200;
    fetchHistoyMessage();
  };

  useEffect(() => {
    chatBoxRef.current?.addEventListener("scroll", handleScroll);
    return () =>
      chatBoxRef.current?.removeEventListener("scroll", handleScroll);
  }, [isLoading]);

  useEffect(() => {
    fetchHistoyMessage();
  }, []);

  useEffect(() => {
    if (
      chatBoxRef.current &&
      (historyMessage.length <= 10 || isSelectedExample)
    ) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
      setIsSelectedExample(false);
    }
  }, [historyMessage]);

  console.log(userPaymentMode);

  return (
    <Wrapper>
      <Title>Kardo AI Assistant</Title>

      {userPaymentMode === 1 ? (
        <Error>
          Please upgrade to standard or premium plan to access this feature
        </Error>
      ) : (
        <WrapperContent>
          {isLoading && (
            <ThreeDots
              visible={true}
              width="fit-content"
              height="15"
              color="#505050"
              radius="9"
              wrapperStyle={{}}
              wrapperClass=""
            />
          )}
          <WrapperChatContent ref={chatBoxRef}>
            {historyMessage.map((item: ChatDto) => (
              <WrapperGap>
                <UserQuestion content={item.question} />
                <AnswerComponent
                  content={item.answer}
                  isReplying={item.answer === "" ? true : false}
                />
              </WrapperGap>
            ))}
          </WrapperChatContent>
          {isShowExample && (
            <WrapperExampleQuestion>
              {exampleQuestions.map((item) => (
                <ExampleQuestion
                  onClick={() => {
                    setQuestion(item);
                    handleSendMessage(item);
                    setIsSelectedExample(true);
                    setIsShowExample(false);
                  }}
                >
                  {item}
                </ExampleQuestion>
              ))}
            </WrapperExampleQuestion>
          )}
          {/* <RegenerateButton>
          <ReloadIcon />
          Regenerate response
        </RegenerateButton> */}
          <WrapperInput>
            <SendIcon
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                margin: "auto",
                right: "24px",
                cursor: "pointer",
                pointerEvents: isAsking ? "none" : "auto",
              }}
              onClick={() => handleSendMessage()}
            />
            <SInput
              placeholder="Type a message"
              onChange={(e: any) => {
                setQuestion(e.target.value);
                setInputValue(e.target.value);
              }}
              value={inputValue}
              disabled={isAsking}
            />
          </WrapperInput>
        </WrapperContent>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 80vh;
`;

const Title = styled.h2`
  margin: 0;
  color: #f5f5f5;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: "DM Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.72px;
  margin-bottom: 30px;
`;

const WrapperContent = styled.div`
  padding: 0 10%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const WrapperGap = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

const WrapperChatContent = styled.div`
  flex: 1;
  padding-right: 12px;
  box-sizing: border-box;
  padding-bottom: 8px;
  display: flex;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scroll-behavior: "smooth";

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(63, 67, 80, 0.24);
  }
`;

const RegenerateButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 45px;
  border: 1px solid #516ca4;
  background-color: transparent;
  outline: none;
  width: fit-content;
  padding: 22px;
  color: #3b5998;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin: auto;
  cursor: pointer;
  gap: 10px;
  margin-bottom: 28px;
  margin-top: 28px;
`;

const SInput = styled.input`
  display: flex;
  padding: 18px;
  width: 100%;
  align-items: center;
  border-radius: 14px;
  border: 1px solid #516ca4;
  background-color: transparent;
  outline: none;
  box-sizing: border-box;
`;

const WrapperInput = styled.div`
  position: relative;
`;

const WrapperExampleQuestion = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 12px 0;
`;

const ExampleQuestion = styled.div`
  border-radius: 8px;
  background-color: #fff;
  padding: 14px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  width: calc(50% - 8px);
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.2s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Poppins;

  :hover {
    background-color: #f0f0f0;
  }
`;

const Error = styled.div`
  width: 100%;
  text-align: center;
  color: #af0000;
  font-family: Poppins;
  font-weight: bold;
`;

export default ChatBotComponent;
