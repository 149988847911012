import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import MainLayout from "./containers/MainLayout";
import MainLayoutAdmin from "./containers/MainLayout/admin";
import PrivateRoutes from "./core/PrivateRoutes";
import AuthLayout from "./containers/Authentication/layout";
import AppsComponent from "./containers/Apps";
import FileComponent from "./containers/File";
import SignalComponent from "./containers/Signal";
import WalletComponent from "./containers/Wallet";
import MerchantAdmin from "./dashboard/Merchants";
import CardAdmin from "./dashboard/Card";
import TypeAdmin from "./dashboard/Types";
import VerifySuccess from "./containers/VerifySuccess";
import ChatBotComponent from "./components/ChatBotComponent";
import PaymentScreen from "./containers/Payment";
import CheckoutForm from "./containers/Checkout";
import { useAppDispatch } from "./store";
import { getUserInfoThunk } from "./store/actions/auth.action";
import ResetPasswordPage from "./containers/Authentication/reset_password";

export default function App() {
  // const authState = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const fetchUserInfo = async () => {
    try {
      await dispatch(getUserInfoThunk()).unwrap();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  return (
    <Routes>
      <Route element={<PrivateRoutes Layout={MainLayoutAdmin} />}>
        <Route path="/admin-merchant" element={<MerchantAdmin />} />
        <Route path="/admin-card" element={<CardAdmin />} />
        <Route path="/admin-type" element={<TypeAdmin />} />
      </Route>
      <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
      <Route element={<MainLayout />}>
        <Route path="/" element={<AppsComponent />} />
        <Route path="/file" element={<FileComponent />} />
        <Route path="/signal" element={<SignalComponent />} />
      </Route>
      <Route path="/verify-page" element={<VerifySuccess />} />
      <Route element={<AuthLayout Layout={MainLayout} />}>
        <Route path="/wallet" element={<WalletComponent />} />
        <Route path="/chat-bot" element={<ChatBotComponent />} />
        <Route path="/payment" element={<PaymentScreen />} />
        <Route path="/checkout" element={<CheckoutForm />} />
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
