import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import {
  getRfToken,
  setAcToken,
  setRfToken,
} from "../core/utils/local-storage";
import { AppStore } from "../store";

axios.defaults.baseURL = process.env.REACT_APP_DEVELOPMENT_URL;

export const configAxios = (store: AppStore): void => {
  async function authHeadersReqHandler(config: AxiosRequestConfig) {
    if (localStorage.getItem("ac_token")) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${localStorage.getItem("ac_token")}`,
      };
    }
    return config;
  }
  axios.interceptors.request.use(authHeadersReqHandler);

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== "/auth/login" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            const rs = await axios.post("/user/token", {
              refreshToken: getRfToken(),
            });

            const { token, refreshToken } = rs.data.data;
            setAcToken(token);
            setRfToken(refreshToken);

            return axios(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }

      return Promise.reject(err);
    }
  );
};
