import VerifyCodePage from "../Authentication/verify_code_page";
import styled from "styled-components";
import successIcon from "../../assets/success_verify.svg";
import { useNavigate } from "react-router-dom";

const VerifySuccess = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <IconSuccess src={successIcon} />
      <Title>Verified!</Title>
      <Subtitle>You have successfully verified account.</Subtitle>
      <SubmitButton onClick={() => navigate("/")}>View our page</SubmitButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`;

const SubmitButton = styled.button`
  border-radius: 10px;
  background: #007fff;
  border: none;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 24px;
  margin-top: 32px;
  width: fit-content;
  cursor: pointer;
  font-family: Outfit;

  :hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
`;

const IconSuccess = styled.img`
  width: 180px;
  height: 180px;
  margin-bottom: 34px;
`;

const Title = styled.h1`
  color: #333333;
  font-size: 60px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0;
  margin-bottom: 12px;
  font-family: Outfit;
`;

const Subtitle = styled.p`
  color: #6c6c6c;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  margin-bottom: 24px;
  font-family: Outfit;
`;

export default VerifySuccess;
