import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import logo from "../../assets/logo.png";
import { ReactComponent as AppsIcon } from "../../assets/apps.svg";
import { ReactComponent as FileIcon } from "../../assets/file.svg";
import { ReactComponent as SignalIcon } from "../../assets/signal.svg";
import { ReactComponent as WalletIcon } from "../../assets/wallet.svg";
import { ReactComponent as LogoutIcon } from "../../assets/logout.svg";
import { ReactComponent as SettingIcon } from "../../assets/setting.svg";
import { ReactComponent as AdminIcon } from "../../assets/admin.svg";
import { useAppDispatch, useAppSelector } from "../../store";
import { logout } from "../../store/actions/auth.action";
import {
  AuthStepContext,
  AuthStepContextType,
  AuthStepEnum,
} from "../../context/authStep";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";

const SidebarMenu = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { handleSetStep } = useContext(AuthStepContext) as AuthStepContextType;
  const currentPath = window.location.pathname.replace(/\//g, "");
  const authState = useAppSelector((state) => state.auth);

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      // handleSetStep(AuthStepEnum.LOGIN);
    } catch (exception: any) {
      console.log(exception);
    }
  };

  return (
    <Wrapper>
      <Logo src={logo} />
      <ListMenuItems>
        <Tooltip title="Merchant dashboard" arrow placement="right">
          <WrapperIcon>
            <AppsIcon
              fill={currentPath === "admin-merchant" ? "#304DAF" : "#2E2E2E40"}
              onClick={() => navigate("/admin-merchant")}
            />
          </WrapperIcon>
        </Tooltip>
        <Tooltip title="Card dashboard" arrow placement="right">
          <WrapperIcon>
            <FileIcon
              fill={currentPath === "admin-card" ? "#304DAF" : "#2E2E2E40"}
              onClick={() => navigate("/admin-card")}
            />
          </WrapperIcon>
        </Tooltip>
        <Tooltip title="Transaction type dashboard" arrow placement="right">
          <WrapperIcon>
            <SignalIcon
              fill={currentPath === "admin-type" ? "#304DAF" : "#2E2E2E40"}
              onClick={() => navigate("/admin-type")}
            />
          </WrapperIcon>
        </Tooltip>
        <Tooltip title="User" arrow placement="right">
          <WrapperIcon>
            <AdminIcon
              onClick={async () => {
                navigate("/");
              }}
            />
          </WrapperIcon>
        </Tooltip>
      </ListMenuItems>
      <ListActions>
        <Tooltip title="Logout" arrow placement="right">
          <WrapperIcon>
            <LogoutIcon fill="#2E2E2E" onClick={handleLogout} />
          </WrapperIcon>
        </Tooltip>
      </ListActions>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 90px;
  height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  box-sizing: border-box;
  position: fixed;
`;

const Logo = styled.img`
  width: 80%;
  height: fit-content;
  margin-bottom: 70px;
`;

const WrapperIcon = styled.div`
  display: flex;
  cursor: pointer;
`;

const ListMenuItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

const ListActions = styled.div`
  gap: 40px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 50px;
`;

export default SidebarMenu;
