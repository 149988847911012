import React, { FC } from "react";
import styled from "styled-components";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../../store";
interface IAuthLayoutProps {
  Layout: React.FC;
}

const AuthLayout: FC<IAuthLayoutProps> = ({ Layout }) => {
  const authState = useAppSelector((state) => state.auth);

  return !authState.me ? (
    <Navigate to='/'/>
  ) : (
    <Layout />
  );
};

export default AuthLayout;
