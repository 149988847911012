import {combineReducers} from 'redux';
import auth from './auth.reducer';
import creditCard from './creditCard.reducer';


const rootReducer = combineReducers({
  auth,
  creditCard,
});

export default rootReducer;
