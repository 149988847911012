import { Alert, MenuItem, Snackbar, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import AddedCard from "../AddedCard";
import SearchInput from "../SearchInput";
import { useAppDispatch } from "../../store";
import {
  createUserCardThunk,
  getAllUserCardsThunk,
  getUnUsedCardsThunk,
  getUsedCardsThunk,
} from "../../store/actions/creditCard.action";
import { CardDto } from "../../models/card.model";
import { listCountries } from "../../constants/countries";

interface IUnUsedCard {
  _id: string;
  defaultPrize: number;
  defaultPoint: number;
  rate: number;
  name: string;
  logoUrl: string;
  countryCode: string;
  urlCardHomePage: string;
  isChecked: boolean;
}

const FormAddCard = () => {
  const [unUsedCards, setUnUsedCards] = useState<IUnUsedCard[]>([]);
  const [addedCards, setAddedCards] = useState<string[]>([]);
  const [countryCode, setCountryCode] = useState<string>("");
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [openToastError, setOpenToastError] = useState<boolean>(false);
  const [textToast, setTextToast] = useState<string>("");
  const [textToastError, setTextToastError] = useState<string>("");
  const [filterName, setFilerName] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const dispatch = useAppDispatch();

  const fetchData = async () => {
    try {
      const resUnUsedCards = await dispatch(
        getUnUsedCardsThunk({
          countryCode: countryCode,
          name: debouncedSearchTerm,
        })
      ).unwrap();
      setUnUsedCards(
        resUnUsedCards.map((item) => {
          return {
            ...item,
            isChecked: false,
          };
        })
      );
    } catch (exception: any) {}
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setDebouncedSearchTerm(filterName);
    }, 500);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [filterName]);

  useEffect(() => {
    fetchData();
  }, [countryCode, debouncedSearchTerm]);

  const onChange = async (cardId: string) => {
    const tempCard = [...addedCards];
    const index = addedCards.findIndex((item) => item === cardId);
    const indexUnUsedCard = unUsedCards.findIndex(
      (item) => item._id === cardId
    );

    const newUnUsedCard = [...unUsedCards];
    newUnUsedCard[indexUnUsedCard] = {
      ...newUnUsedCard[indexUnUsedCard],
      isChecked: !newUnUsedCard[indexUnUsedCard].isChecked,
    };

    setUnUsedCards(newUnUsedCard);

    if (index === -1) {
      tempCard.push(cardId);
    } else {
      tempCard.splice(index, 1);
    }
    setAddedCards(tempCard);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const handleCloseToastError = () => {
    setOpenToastError(false);
  };

  const handleAddCard = async () => {
    try {
      await dispatch(createUserCardThunk({ cardIds: addedCards })).unwrap();
      await dispatch(getAllUserCardsThunk()).unwrap();
      const resUnUsedCards = await dispatch(
        getUnUsedCardsThunk({ countryCode: countryCode })
      ).unwrap();
      await dispatch(getUsedCardsThunk({})).unwrap();
      setUnUsedCards(
        resUnUsedCards.map((item) => {
          return {
            ...item,
            isChecked: false,
          };
        })
      );
      setOpenToast(true);
      setTextToast("The card has been successfully added!");
    } catch (exception: any) {
      setOpenToastError(true);
      setTextToastError(exception.message)
    }
  };

  const handleChangeCountry = async (
    event: React.ChangeEvent<{ value: string }>
  ) => {
    setCountryCode(event.target.value === "all" ? "" : event.target.value);
  };

  const handleChangeSearchInput = (value: string) => {
    setFilerName(value);
  };

  return (
    <Wrapper>
      <Title>Explore Cards</Title>
      <WrapperTabContent>
        <WarpperFilter>
          <WrapperFilterInput>
            <SearchInput
              handleChange={handleChangeSearchInput}
              placeholder="Search for cards"
            />
          </WrapperFilterInput>
          <WrapperFilterInput>
            <TextField
              select
              defaultValue={"null"}
              fullWidth
              inputProps={{
                sx: {
                  padding: "12px",
                },
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  zIndex: "999",
                  backgroundColor: '#fff'
                },
              }}
              onChange={handleChangeCountry}
            >
              <MenuItem value="null" disabled style={{ display: "none" }}>
                Please select your country
              </MenuItem>
              <MenuItem value="all">Show all cards</MenuItem>
              {listCountries?.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </TextField>
          </WrapperFilterInput>
        </WarpperFilter>
        <WrapperListCard>
          {unUsedCards?.length > 0 &&
            unUsedCards.map((item, index) => (
              <AddedCard
                idCard={item._id}
                key={index}
                imgSrc={item.logoUrl}
                name={item.name}
                isChecked={item.isChecked}
                onChange={() => onChange(item._id)}
              />
            ))}
          {unUsedCards?.length === 0 && (
            <NoCreditCardText>You added all cards</NoCreditCardText>
          )}
        </WrapperListCard>
      </WrapperTabContent>
      {unUsedCards?.length > 0 && (
        <WrapperAddCard>
          <BtnAddCard
            disabled={addedCards.length === 0}
            onClick={handleAddCard}
          >
            Add Card(s)
          </BtnAddCard>
        </WrapperAddCard>
      )}
      <Snackbar
        open={openToast}
        autoHideDuration={2000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseToast}
          severity="success"
          sx={{ width: "100%" }}
        >
          {textToast}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openToastError}
        autoHideDuration={2000}
        onClose={handleCloseToastError}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseToastError}
          severity="error"
          sx={{ width: "100%" }}
        >
          {textToastError}
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${props => props.theme.colors.background};
`;

const Title = styled.h1`
  color: #3D5B97;
  font-family: DM Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  margin-bottom: 24px;

  @media (max-width: 900px) {
    font-size: 24px;
  }
`;

const WrapperTabContent = styled.div`
  background-color: #C1C9DC;
  border: 0.25px solid rgba(109, 125, 147, 0.15);
  border-radius: 8px;
  padding: 14px 24px;
`;

const WrapperFilterInput = styled.div`
  width: 50%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const WarpperFilter = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  gap: 24px;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const WrapperListCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  height: 360px;
  overflow: auto;
  padding-right: 10px;

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(63, 67, 80, 0.24);
  }
`;

const NoCreditCardText = styled.p`
  margin: 0;
  color: #c4c4c4;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  margin-top: 20px;
`;

const WrapperAddCard = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const BtnAddCard = styled.button`
  border-radius: 27.5px;
  background: #4B7BD2;
  box-shadow: 0px 20px 48px -12px rgba(27, 105, 253, 0.35);
  color: #fff;
  text-align: center;
  font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  padding: 4px 24px;
  margin-top: 24px;
  width: fit-content;
  cursor: pointer;

  @media (max-width: 900px) {
    font-size: 16px;
  }
`;

export default FormAddCard;
