/* eslint-disable max-len */
import axios, { AxiosResponse } from "axios";
import { ChatDto } from "../models/chat.model";

const ChatService = {
  createQuestion(question: string): Promise<AxiosResponse<{ data: ChatDto }>> {
    return axios.post("/chat-msg", { question });
  },
  getHistory(
    page: number
  ): Promise<AxiosResponse<{ data: { items: ChatDto[]; total: number } }>> {
    return axios.get(`/chat-msg?page=${page}`);
  },
  getAnswer(id: string): Promise<AxiosResponse<{ data: ChatDto }>> {
    return axios.get(`/chat-msg/${id}`);
  },
};

export default ChatService;
