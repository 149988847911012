import { TypeDto } from './transaction_type.model';

export enum EnumStatus {
  'Pending' = 1,
  'Completed' = 2,
}

export enum EnumCurrency {
  'USD' = 1,
  'EUR' = 2,
  'JPY' = 3,
  'SGD' = 9,
}

export interface TransactionDto {
  _id: string;
  shop: {
    _id: string;
    name: string;
    mccCode: string;
    logoUrl: string;
  };
  status: EnumStatus;
  amount: number;
  category: TypeDto;
  reward: number;
  createdAt: string;
  userCardId: string;
  currency: EnumCurrency;
}
