import { useAppDispatch, useAppSelector } from "../../store/index";
import { useNavigate } from "react-router-dom";
import { loginThunk, registerThunk } from "../../store/actions/auth.action";
import { useContext, useState } from "react";
import { saveState } from "../../services/localStorage.service";
import styled from "styled-components";
import STextField from "../../components/TextField";
import appleIcon from "../../assets/apple.svg";
import googleIcon from "../../assets/google.svg";
import facebookIcon from "../../assets/facebook.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import successIcon from "../../assets/success.svg";
import {
  Alert,
  FormHelperText,
  IconButton,
  InputAdornment,
  Snackbar,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  AuthStepContext,
  AuthStepContextType,
  AuthStepEnum,
} from "../../context/authStep";

type FormValues = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
};

const schema = yup.object({
  email: yup
    .string()
    .required("Email is required!")
    .email("Email format is not valid!"),
  firstName: yup
    .string()
    .required("First Name is required!")
    .min(3, "First Name must be at least 3 characters")
    .max(255, "First Name must be at most 255 characters"),
  lastName: yup
    .string()
    .required("Last Name is required!")
    .min(3, "Last Name must be at least 3 characters")
    .max(255, "Last Name must be at most 255 characters"),
  password: yup
    .string()
    .required("Password is required!")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required!")
    .oneOf([yup.ref("password")], "Please make sure your password match!"),
});

const RegisterForm = () => {
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { handleSetStep } = useContext(AuthStepContext) as AuthStepContextType;
  const form = useForm({
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, formState } = form;
  const { errors } = formState;
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const [registerSuccess, setRegisterSuccess] = useState(false);

  const dispatch = useAppDispatch(),
    authState = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  const onSubmit = async (data: FormValues) => {
    try {
      await dispatch(
        registerThunk({
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          password: data.password,
        })
      ).unwrap();
      setRegisterSuccess(true);
    } catch (exception: any) {
      if (exception.message === "this email has been using") {
        setError("Email is already registered before!");
      } else {
        setError("Please check your information!");
      }
    }
    saveState({ auth: authState });
  };

  const handleBack = () => {
    handleSetStep(AuthStepEnum.LOGIN);
  };

  return (
    <Wrapper>
      {!registerSuccess ? (
        <>
          <Title>Welcome to Cash Miles</Title>
          <WrapperSubTitle>
            <SubTitle>
              Unlock endless rewards. Let's start your journey!
            </SubTitle>
          </WrapperSubTitle>
          <WrapperForm>
            <Form onSubmit={handleSubmit(onSubmit)} noValidate>
              <WrapperInputName>
                <WrapperInput>
                  <WrapperInputTitle>
                    <InputTitle>First Name</InputTitle>
                    <Required>*</Required>
                  </WrapperInputTitle>
                  <TextField
                    type="text"
                    fullWidth
                    inputProps={{
                      sx: {
                        padding: "12px",
                      },
                    }}
                    {...register("firstName")}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={!!errors.firstName}
                    helperText={
                      <FormHelperText
                        style={{ fontFamily: "Outfit", margin: "0" }}
                      >
                        {errors.firstName?.message}
                      </FormHelperText>
                    }
                  />
                </WrapperInput>
                <WrapperInput>
                  <WrapperInputTitle>
                    <InputTitle>Last Name</InputTitle>
                    <Required>*</Required>
                  </WrapperInputTitle>
                  <TextField
                    type="text"
                    fullWidth
                    inputProps={{
                      sx: {
                        padding: "12px",
                      },
                    }}
                    {...register("lastName")}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={!!errors.lastName}
                    helperText={
                      <FormHelperText
                        style={{ fontFamily: "Outfit", margin: "0" }}
                      >
                        {errors.lastName?.message}
                      </FormHelperText>
                    }
                  />
                </WrapperInput>
              </WrapperInputName>
              <WrapperInput>
                <WrapperInputTitle>
                  <InputTitle>Email</InputTitle>
                  <Required>*</Required>
                </WrapperInputTitle>
                <TextField
                  type="text"
                  fullWidth
                  inputProps={{
                    sx: {
                      padding: "12px",
                    },
                  }}
                  {...register("email")}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                  error={!!errors.email}
                  helperText={
                    <FormHelperText
                      style={{ fontFamily: "Outfit", margin: "0" }}
                    >
                      {errors.email?.message}
                    </FormHelperText>
                  }
                />
              </WrapperInput>
              <WrapperInput>
                <WrapperInputTitle>
                  <InputTitle>Password</InputTitle>
                  <Required>*</Required>
                </WrapperInputTitle>
                <TextField
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  inputProps={{
                    sx: {
                      padding: "12px",
                    },
                  }}
                  {...register("password")}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.password}
                  helperText={
                    <FormHelperText
                      style={{ fontFamily: "Outfit", margin: "0" }}
                    >
                      {errors.password?.message}
                    </FormHelperText>
                  }
                />
              </WrapperInput>
              <WrapperInput>
                <WrapperInputTitle>
                  <InputTitle>Confirm Password</InputTitle>
                  <Required>*</Required>
                </WrapperInputTitle>
                <TextField
                  type={showConfirmPassword ? "text" : "password"}
                  fullWidth
                  inputProps={{
                    sx: {
                      padding: "12px",
                    },
                  }}
                  {...register("confirmPassword")}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.confirmPassword}
                  helperText={
                    <FormHelperText
                      style={{ fontFamily: "Outfit", margin: "0" }}
                    >
                      {errors.confirmPassword?.message}
                    </FormHelperText>
                  }
                />
              </WrapperInput>
              <Error>{error}</Error>
              <SubmitButton type="submit">Sign Up</SubmitButton>
              <MoreOptions>Or Continue with</MoreOptions>
              <WrapperLoginOptions>
                <WrapperLoginIcon>
                  <IconLogin src={googleIcon} alt="" />
                </WrapperLoginIcon>
                <WrapperLoginIcon>
                  <IconLogin src={facebookIcon} alt="" />
                </WrapperLoginIcon>
                <WrapperLoginIcon>
                  <IconLogin src={appleIcon} alt="" />
                </WrapperLoginIcon>
              </WrapperLoginOptions>
            </Form>
          </WrapperForm>
          <WrapperEndtitle>
            <SubTitle>Don't have an account?</SubTitle>
            <Login onClick={() => handleSetStep(AuthStepEnum.LOGIN)}>
              Login
            </Login>
          </WrapperEndtitle>
        </>
      ) : (
        <WrapperSuccessSection>
          <SuccessTitle>Thank you for signing up with Kardo.ai</SuccessTitle>
          <IconSuccess src={successIcon} />
          <SubTitle>
            Your registration is successful. Please check your email to verify
            your account.
          </SubTitle>
          <BackButton onClick={handleBack}>Back to Login</BackButton>
        </WrapperSuccessSection>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: auto;

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(63, 67, 80, 0.24);
  }
`;

const Title = styled.h1`
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  font-family: Outfit;
  @media (max-width: 900px) {
    font-size: 36px;
  }
`;

const SuccessTitle = styled.h1`
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  font-family: Outfit;
  text-align: center;
`;

const WrapperSubTitle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 42px;
  margin-top: 8px;
`;

const SubTitle = styled.p`
  color: #6c6c6c;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  font-family: Outfit;
`;

const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapperInputName = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
`;

const WrapperInput = styled.div`
  margin-bottom: 16px;
  width: 100%;
`;

const SubmitButton = styled.button`
  border-radius: 10px;
  background: #007fff;
  border: none;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 0;
  margin-top: 24px;
  cursor: pointer;
  width: 100%;
  font-family: Outfit;

  :hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
`;

const MoreOptions = styled.p`
  color: #757171;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin: 0;
  margin-top: 31px;
  font-family: Outfit;
`;

const WrapperLoginOptions = styled.div`
  display: flex;
  gap: 22px;
  justify-content: center;
  margin-top: 31px;
`;

const WrapperLoginIcon = styled.div`
  border-radius: 50%;
  background: #f3f3f3;
  width: fit-content;
  cursor: pointer;
`;

const WrapperEndtitle = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  font-size: 16px;
`;

const IconLogin = styled.img`
  width: 22px;
  height: 22px;
  padding: 22px;
`;

const Login = styled.p`
  margin: 0;
  color: #007fff;
  font-style: normal;
  font-weight: 700;
  text-decoration-line: underline;
  margin-left: 4px;
  cursor: pointer;
`;

const Form = styled.form``;

const Error = styled.p`
  margin: 0;
  color: #d32f2f;
  font-size: 18px;
  margin-top: 32px;
`;

const WrapperInputTitle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`;

const Required = styled.p`
  margin: 0;
  color: red;
  margin-left: 6px;
`;

const InputTitle = styled.p`
  color: rgba(0, 0, 0, 0.93);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  font-family: Outfit;
`;

const WrapperSuccessSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const IconSuccess = styled.img`
  width: 60px;
  height: 60px;
`;

const BackButton = styled.button`
  border-radius: 10px;
  border: 1px solid #6c6c6c;
  background: #fff;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 0;
  cursor: pointer;
  width: 100%;
  color: #000;
  margin-top: 24px;
  font-family: Outfit;

  :hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
`;

export default RegisterForm;
