import { createReducer } from "@reduxjs/toolkit";
import {
  getAllUserCardsThunk,
  getCardsThunk,
  getUnUsedCardsThunk,
  getUsedCardsThunk,
} from "../actions/creditCard.action";
import { CardDto } from "../../models/card.model";

export interface CardState {
  loading: boolean;
  usedCards: CardDto[];
  unUsedCards: CardDto[];
  cards: CardDto[];
  allUserCards: CardDto[]
}

const initialState: CardState = {
  loading: false,
  usedCards: [],
  unUsedCards: [],
  cards: [],
  allUserCards: []
};

const CardReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getUsedCardsThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(getUsedCardsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.usedCards = action.payload;
    })
    .addCase(getUsedCardsThunk.rejected, (state) => {
      state.loading = false;
    })
    .addCase(getAllUserCardsThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(getAllUserCardsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.allUserCards = action.payload;
    })
    .addCase(getAllUserCardsThunk.rejected, (state) => {
      state.loading = false;
    })
    .addCase(getUnUsedCardsThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(getUnUsedCardsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.unUsedCards = action.payload;
    })
    .addCase(getUnUsedCardsThunk.rejected, (state) => {
      state.loading = false;
    })
    .addCase(getCardsThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(getCardsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.cards = action.payload;
    })
    .addCase(getCardsThunk.rejected, (state) => {
      state.loading = false;
    })
});

export default CardReducer;
