import styled from "styled-components";
import { ReactComponent as BotReplied } from "../../assets/replied_robot.svg";
import { ReactComponent as BotReplying } from "../../assets/replying_robot.svg";
import { FC } from "react";
import { ThreeDots } from "react-loader-spinner";

interface IAnswerComponent {
  content: string;
  isReplying?: boolean;
}

const AnswerComponent: FC<IAnswerComponent> = ({
  content,
  isReplying = false,
}) => {
  return (
    <Wrapper>
      {isReplying ? (
        <>
          <BotReplying />
          <WrapperContent>
            <ThreeDots
              visible={true}
              width="fit-content"
              height="15"
              color="#505050"
              radius="9"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </WrapperContent>
        </>
      ) : (
        <>
          <BotReplied />
          <WrapperContent>{content}</WrapperContent>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: inline-flex;
`;

const WrapperContent = styled.div`
  margin-left: 8px;
  display: inline-flex;
  padding: 18px 19px 18px 22px;
  border-radius: 0px 25px 25px 25px;
  background: #f0f8ff;
  box-shadow: 14px 27px 45px 4px rgba(112, 144, 176, 0.2);
  width: fit-content;
  overflow-wrap: anywhere;
  color: #505050;
  font-family: "Plus Jakarta Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
`;

export default AnswerComponent;
