import ForgotPasswordPage from "../containers/Authentication/forgot_password";
import LoginForm from "../containers/Authentication/login_form";
import ResetPasswordPage from "../containers/Authentication/reset_password";
import RegisterForm from "../containers/Authentication/signup_form";
import VerifyCodePage from "../containers/Authentication/verify_code_page";
import GetStartedComponent from "../containers/GetStarted";
import { AuthStepEnum } from "../context/authStep";

const AuthSteps = [
    {
        step: AuthStepEnum.GET_STARTED,
        component: <GetStartedComponent />
    },
    {
        step: AuthStepEnum.LOGIN,
        component: <LoginForm />
    },
    {
        step: AuthStepEnum.REGISTER,
        component: <RegisterForm />
    },
    {
        step: AuthStepEnum.FORGOT_PASSWORD,
        component: <ForgotPasswordPage />
    },
    {
        step: AuthStepEnum.RESET_PASSWORD,
        component: <ResetPasswordPage />
    },
    {
        step: AuthStepEnum.VERIFY_CODE,
        component: <VerifyCodePage />
    },
    {
        step: AuthStepEnum.SUCCESS,
    },
]

export default AuthSteps;