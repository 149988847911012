import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { Dialog } from "@mui/material";
import { DialogContent } from "@mui/joy";
import AuthSteps from "../../constants/auth_step";
import {
  AuthStepContext,
  AuthStepContextType,
  AuthStepEnum,
} from "../../context/authStep";
import { useAppSelector } from "../../store";
import SidebarMenu from "../../components/SidebarMenu/admin";

const MainLayout = () => {
  const { step, handleSetStep } = React.useContext(
    AuthStepContext
  ) as AuthStepContextType;
  const [open, setOpen] = React.useState(true);
  const authState = useAppSelector((state) => state.auth);

  const handleClose = () => {
    handleSetStep(AuthStepEnum.SUCCESS);
    setOpen(false);
  };

  React.useEffect(() => {
    if (authState.me) {
      handleSetStep(AuthStepEnum.SUCCESS);
    }
    setOpen(true);
  }, [step, authState.me]);

  return (
    <Wrapper>
      <SidebarMenu />
      {step !== AuthStepEnum.SUCCESS && (
        <Dialog
          sx={{
            "& .MuiBackdrop-root": {
              backdropFilter: "blur(10px)",
              boxShadow: "0px 0px 10px 5px rgba(34, 41, 70, 0.50)",
            },
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                borderRadius: "50px",
                minHeight: "600px",
                maxWidth:
                  step === AuthStepEnum.GET_STARTED ? "1200px" : "800px",
              },
            },
          }}
          open={open}
          onClose={handleClose}
        >
          <SDialogContent
            style={{
              padding: "64px",
              justifyContent: "center",
              display: "flex",
            }}
          >
            {AuthSteps.find((item) => item.step === step)?.component}
          </SDialogContent>
        </Dialog>
      )}
      <WrapperOutlet>
        <Outlet />
      </WrapperOutlet>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: #f8f9fd;
  display: flex;
`;

const WrapperOutlet = styled.div`
  box-sizing: border-box;
  padding: 0 30px 0px 120px;
  width: 100%;
`;

const SDialogContent = styled(DialogContent)`
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
  }

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c1c1c1;
  }
`;

export default MainLayout;
