import { CheckOutlined } from "@mui/icons-material";
import { PaymentElement } from "@stripe/react-stripe-js";
import { FC } from "react";
import styled from "styled-components";

interface IPlan {
  name: string;
  price?: number;
  benefits: React.ReactElement[];
  onClick?: () => void;
  isSubscribed: boolean;
}

const PlanComponent: FC<IPlan> = ({
  name,
  price,
  benefits,
  onClick,
  isSubscribed,
}) => {
  return (
    <Wrapper>
      <Tag isSubscribed={isSubscribed}>{name}</Tag>
      {price && (
        <WrapperPrice>
          <Price>${price}</Price>
          <Unit>/month</Unit>
        </WrapperPrice>
      )}
      <WrapperListBenefits>
        {benefits.map((item) => (
          <BenefitItem>{item}</BenefitItem>
        ))}
      </WrapperListBenefits>
      {isSubscribed ? (
        <SubscribedBtn>
          <SCheckOutlined color="inherit" /> Subscribed
        </SubscribedBtn>
      ) : (
        <GetStartedBtn onClick={onClick}>Get Started</GetStartedBtn>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #fff;
  padding: 24px;
  border-radius: 12px;
  font-family: DM Sans;
  height: fit-content;
  box-shadow: "1px 2px 9px #e7e7e7";
`;

const Tag = styled.div<{ isSubscribed: boolean }>`
  background-color: ${(props) =>
    props.isSubscribed ? "#44cc46" : "#3b5998cc"};
  font-family: DM Sans;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  width: fit-content;
  padding: 6px 12px;
  border-radius: 12px;
`;

const WrapperPrice = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 24px;
`;

const Price = styled.h1`
  padding: 0;
  margin: 0;
  stroke-width: 2;
  stroke: black;
  font-size: 32px;
  line-height: 32px;
`;

const Unit = styled.p`
  margin: 0;
  padding: 0;
  color: gray;
`;

const WrapperListBenefits = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  padding-top: 24px;
  padding-bottom: 24px;
`;

const BenefitItem = styled.div`
  font-size: 16px;
  font-weight: 600;
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const GetStartedBtn = styled.button`
  border: none;
  background-color: #3b5998cc;
  color: #fff;
  font-family: DM Sans;
  padding: 8px;
  width: 100%;
  margin-top: 8px;
  font-size: 18px;
  border-radius: 50px;
  cursor: pointer;

  :hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
`;

const SubscribedBtn = styled.button`
  border: none;
  background-color: #44cc46;
  color: #fff;
  font-family: DM Sans;
  padding: 8px;
  width: 100%;
  margin-top: 8px;
  font-size: 18px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
`;

const SCheckOutlined = styled(CheckOutlined)`
  padding-right: 4px;
`;

export default PlanComponent;
