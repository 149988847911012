import styled from "styled-components";
import savedIcon from "../../assets/saved.svg";
import bonusIcon from "../../assets/mile.svg";

interface ICardMerchant {
  logo: string;
  name: string;
  kpoint: number;
  cashback: number;
  isSaved?: boolean;
}

const CardMerchant = ({
  logo,
  name,
  kpoint,
  cashback,
  isSaved = false,
}: ICardMerchant) => {
  return (
    <Wrapper>
      <WrapperLogo>
        <Logo src={logo} />
      </WrapperLogo>
      {isSaved && <SaveIcon src={savedIcon} />}
      <Name>{name}</Name>
      <WrapperKBonus>
        <BonusType>Best Mile Points</BonusType>
        <UptoText>up to</UptoText>
        <Bonus>{kpoint}/usd</Bonus>
      </WrapperKBonus>
      <WrapperCBonus>
        <BonusType>Best Cashback Site</BonusType>
        <UptoText>up to</UptoText>
        <Bonus>{cashback}%</Bonus>
      </WrapperCBonus>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 18px;
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
`;

const WrapperLogo = styled.div`
  padding: 8px;
  background-color: #fff;
  border-radius: 8px;
  min-height: 70px;
  display: flex;
  align-items: center;
  width: 85%;
  box-sizing: border-box;
  height: 80px;
  justify-content: center;
`;

const Logo = styled.img`
  background-color: #fff;
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const SaveIcon = styled.img`
  position: absolute;
  right: 8px;
  top: 8px;
`;

const Name = styled.p`
  margin: 0;
  color: #121212;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.36px;
  margin-bottom: 8px;
  margin-top: 4px;
`;

const WrapperCBonus = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  flex-shrink: 0;
  margin: 0;
  border-radius: 8px;
  border: 0.75px solid rgba(35, 35, 33, 0.3);
  background: linear-gradient(
    259deg,
    #aabad7 30.52%,
    rgba(255, 255, 255, 0) 124.09%
  );
  box-sizing: border-box;
`;

const WrapperKBonus = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  flex-shrink: 0;
  margin: 0;
  margin-bottom: 12px;
  border-radius: 8px;
  border: 0.75px solid rgba(35, 35, 33, 0.3);
  background: linear-gradient(
    259deg,
    #ffd1a9 30.52%,
    rgba(255, 209, 169, 0) 124.09%
  );
  box-sizing: border-box;
`;

const BonusType = styled.p`
  margin: 0;
  color: var(--Dark-Gray, #232321);
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 40%;
`;

const UptoText = styled.text`
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  font-family: "Open Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const Bonus = styled.p`
  margin: 0;
  color: #000;
  text-align: right;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 20%;
  word-wrap: break-word;
`;

const WrapperBonusIcon = styled.div`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #51a1fe;
  margin-right: 5px;
`;

const BonusIcon = styled.img`
  width: 20px;
`;

export default CardMerchant;
