import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import {
  Button,
  FormHelperText,
  LinearProgress,
  MenuItem,
  Select,
} from "@mui/material";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { TypeDto } from "../../models/transaction_type.model";
import { useAppDispatch } from "../../store";
import { createCardThunk } from "../../store/actions/creditCard.action";
import {
  createMerchantThunk,
  updateMerchantThunk,
} from "../../store/actions/merchant.action";

type FormValues = {
  id: string;
  name: string;
  code: number;
  type: string;
  discount: number;
  cashback: number;
  redirectUrl: string;
  logo: any;
};

const schema = yup.object({
  name: yup.string().required("Name is required!"),
  code: yup.string().required("Code is required!"),
  type: yup.string().required("Type is required!"),
  discount: yup.string().required("Discount is required!"),
  cashback: yup.string().required("Cashback is required!"),
  redirectUrl: yup.string().required("Url is required!"),
});

interface IFormMerchants {
  data?: FormValues;
  listTypes: any[];
  refetchData?: () => void;
  handleSuccess?: () => void;
}

const FormMerchants: FC<IFormMerchants> = ({
  data = undefined,
  listTypes,
  refetchData,
  handleSuccess,
}) => {
  const [error, setError] = useState<string>("");
  const [image, setImage] = useState<string | null>(null);

  const onSubmit = async (dataForm: FormValues) => {
    if (data) {
      await dispatch(
        updateMerchantThunk({
          id: data.id,
          data: {
            name: dataForm.name,
            discount: dataForm.discount,
            cashback: dataForm.cashback,
            image: dataForm.logo,
            mccCode: dataForm.code,
            redirectUrl: dataForm.redirectUrl,
            transactionTypeId: dataForm.type,
          },
        })
      );
    } else {
      await dispatch(
        createMerchantThunk({
          name: dataForm.name,
          image: dataForm.logo,
          transactionTypeId: dataForm.type,
          mccCode: dataForm.code,
          redirectUrl: dataForm.redirectUrl,
          discount: dataForm.discount,
          cashback: dataForm.cashback,
        })
      ).unwrap();
    }

    refetchData && refetchData();
    handleSuccess && handleSuccess();
  };

  const dispatch = useAppDispatch();
  const form = useForm<FormValues>({
    defaultValues: data || {
      name: "",
      code: undefined,
      type: "",
      discount: undefined,
      cashback: undefined,
      redirectUrl: "",
      logo: undefined,
    },
    resolver: yupResolver(schema) as any,
  });
  const { register, handleSubmit, formState, getValues, setValue } = form;
  const { errors } = formState;

  const handleChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setValue("logo", file);
      const reader = new FileReader();

      reader.onload = (e) => {
        if (typeof e.target?.result === "string") {
          setImage(e.target.result);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (!getValues("type")) {
      setValue("type", listTypes[0].value);
    }
  }, []);

  return (
    <Wrapper>
      <Title>{data ? "Edit merchant" : "Add new merchant"}</Title>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <WrapperInput>
          <WrapperInputTitle>
            <InputTitle>Name</InputTitle>
          </WrapperInputTitle>
          <TextField
            type="text"
            fullWidth
            placeholder="Enter merchant name here"
            inputProps={{
              sx: {
                padding: "12px",
              },
            }}
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
            {...register("name")}
            error={!!errors.name}
            helperText={
              <FormHelperText style={{ fontFamily: "Outfit", margin: "0" }}>
                {errors.name?.message}
              </FormHelperText>
            }
          />
        </WrapperInput>
        <WrapperInput>
          <WrapperInputTitle>
            <InputTitle>Code</InputTitle>
          </WrapperInputTitle>
          <TextField
            type="text"
            fullWidth
            placeholder="Enter merchant code here"
            inputProps={{
              sx: {
                padding: "12px",
              },
            }}
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
            {...register("code")}
            error={!!errors.code}
            helperText={
              <FormHelperText style={{ fontFamily: "Outfit", margin: "0" }}>
                {errors.code?.message}
              </FormHelperText>
            }
          />
        </WrapperInput>
        <WrapperInput>
          <WrapperInputTitle>
            <InputTitle>Logo</InputTitle>
          </WrapperInputTitle>
          <input
            accept="image/*"
            id="contained-button-file"
            type="file"
            style={{ display: "none" }}
            onChange={handleChangeFile}
            // {...register("logo")}
          />
          {image ? (
            <img src={image} alt="Uploaded" style={{ maxWidth: "100%" }} />
          ) : (
            data && (
              <img
                src={data.logo}
                alt="Uploaded"
                style={{ maxWidth: "100%" }}
              />
            )
          )}
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="primary" component="span">
              Upload
            </Button>
          </label>
          <ErrorImageMessage>
            {errors.logo && errors.logo?.message + "!"}
          </ErrorImageMessage>
        </WrapperInput>
        <WrapperInput>
          <WrapperInputTitle>
            <InputTitle>Type</InputTitle>
          </WrapperInputTitle>
          <TextField
            select
            defaultValue={data?.type || listTypes[0].value}
            fullWidth
            inputProps={{
              sx: {
                padding: "12px",
              },
            }}
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
            {...register("type")}
          >
            {listTypes?.map((item) => (
              <MenuItem value={item.value}>{item.label}</MenuItem>
            ))}
          </TextField>
        </WrapperInput>
        <WrapperInput>
          <WrapperInputTitle>
            <InputTitle>Discount</InputTitle>
          </WrapperInputTitle>
          <TextField
            type="text"
            fullWidth
            placeholder="Enter merchant discount here"
            inputProps={{
              sx: {
                padding: "12px",
              },
            }}
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
            {...register("discount")}
            error={!!errors.discount}
            helperText={
              <FormHelperText style={{ fontFamily: "Outfit", margin: "0" }}>
                {errors.discount?.message}
              </FormHelperText>
            }
          />
        </WrapperInput>
        <WrapperInput>
          <WrapperInputTitle>
            <InputTitle>Cashback</InputTitle>
          </WrapperInputTitle>
          <TextField
            type="text"
            fullWidth
            placeholder="Enter merchant cashback here"
            inputProps={{
              sx: {
                padding: "12px",
              },
            }}
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
            {...register("cashback")}
            error={!!errors.cashback}
            helperText={
              <FormHelperText style={{ fontFamily: "Outfit", margin: "0" }}>
                {errors.cashback?.message}
              </FormHelperText>
            }
          />
        </WrapperInput>
        <WrapperInput>
          <WrapperInputTitle>
            <InputTitle>Redirect URL</InputTitle>
          </WrapperInputTitle>
          <TextField
            type="text"
            fullWidth
            placeholder="Enter redirect url here"
            inputProps={{
              sx: {
                padding: "12px",
              },
            }}
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
            {...register("redirectUrl")}
            error={!!errors.redirectUrl}
            helperText={
              <FormHelperText style={{ fontFamily: "Outfit", margin: "0" }}>
                {errors.redirectUrl?.message}
              </FormHelperText>
            }
          />
        </WrapperInput>
        {error && <ErrorText>{error}</ErrorText>}
        <WrapperButton>
          <SubmitButton type="submit">Submit</SubmitButton>
        </WrapperButton>
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  margin: 0;
  font-family: Poppins;
`;

const WrapperInput = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const WrapperInputTitle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
`;

const InputTitle = styled.p`
  color: rgba(0, 0, 0, 0.93);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  font-family: Outfit;
`;

const ErrorText = styled.p`
  color: red;
  margin-top: 0;
`;

const WrapperButton = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  flex-direction: column;
  margin-top: 16px;
`;

const SubmitButton = styled.button`
  border-radius: 10px;
  background: #007fff;
  border: none;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 0;
  margin-top: 32px;
  width: 100%;
  cursor: pointer;
  font-family: Outfit;

  :hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
`;

const Form = styled.form`
  width: 100%;
`;

const ErrorImageMessage = styled.p`
  color: #d32f2f;
  font-family: Outfit;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  font-weight: 400;
  font-size: 0.75rem;
`;

export default FormMerchants;
