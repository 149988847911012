import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useAppDispatch } from "../../store";
import { resetPassword } from "../../store/actions/auth.action";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormHelperText, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import successIcon from '../../assets/success.svg'

type FormValues = {
  password: string;
  confirmPassword: string;
};

const schema = yup.object({
  password: yup.string().required("Password is required!"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required!")
    .oneOf([yup.ref("password")], "Please make sure your password match"),
});

const ResetPasswordPage = () => {
  const { token } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
  const [error, setError] = useState<string>("");
  const form = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, formState } = form;
  const { errors } = formState;

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const dispatch = useAppDispatch(),
    navigate = useNavigate();

  const onSubmit = async (data: FormValues) => {
    try {
      await dispatch(
        resetPassword({
          password: data.password,
          token: token!,
        })
      ).unwrap();
      setChangePasswordSuccess(true);
    } catch (exception: any) {
      setError(exception.message);
    }
  };

  const handleBack = () => {
    navigate('/login');
  }

  return (
    <Wrapper>
      {!changePasswordSuccess ? (
        <>
          <Title>Reset Password</Title>
          <SubTitle>Create a new password to secure your account.</SubTitle>
          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <WrapperInput>
              <WrapperInputTitle>
                <InputTitle>Password</InputTitle>
              </WrapperInputTitle>
              <TextField
                type={showPassword ? "text" : "password"}
                fullWidth
                inputProps={{
                  sx: {
                    padding: "12px",
                  },
                }}
                {...register("password")}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors.password}
                helperText={<FormHelperText style={{ fontFamily: 'Outfit', margin: '0' }}>{errors.password?.message}</FormHelperText>}
              />
            </WrapperInput>
            <WrapperInput>
              <WrapperInputTitle>
                <InputTitle>Confirm Password</InputTitle>
              </WrapperInputTitle>
              <TextField
                type={showConfirmPassword ? "text" : "password"}
                fullWidth
                inputProps={{
                  sx: {
                    padding: "12px",
                  },
                }}
                {...register("confirmPassword")}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors.confirmPassword}
                helperText={<FormHelperText style={{ fontFamily: 'Outfit', margin: '0' }}>{errors.confirmPassword?.message}</FormHelperText>}
              />
            </WrapperInput>
            {error && <ErrorText>{error}</ErrorText>}
            <WrapperButton>
              <SubmitButton type="submit">Confirm</SubmitButton>
            </WrapperButton>
          </Form>
        </>
      ) : (
        <WrapperSuccessSection>
          <Title>Password Changed!</Title>
          <IconSuccess src={successIcon} />
          <SubTitle>Your password has been changed successfully!</SubTitle>
          <BackButton onClick={handleBack}>Back to Login</BackButton>
        </WrapperSuccessSection>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 30%;
  box-sizing: border-box;
  flex-direction: column;
  overflow: auto;
  margin: auto;
  margin-top: 100px;
`;

const Title = styled.h1`
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  margin-bottom: 8px;
  font-family: Outfit;
`;

const SubTitle = styled.p`
  color: #6c6c6c;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  font-family: Outfit;
`;

const ErrorText = styled.p`
  color: red;
  margin-top: 0;
  font-family: Outfit;
`;

const WrapperInput = styled.div`
  margin-bottom: 8px;
  width: 100%;
  margin-top: 32px;
`;

const WrapperButton = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  flex-direction: column;
  margin-top: 16px;
`;

const SubmitButton = styled.button`
  border-radius: 10px;
  background: #007fff;
  border: none;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 0;
  cursor: pointer;
  font-family: Outfit;

  :hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
`;

const BackButton = styled.button`
  border-radius: 10px;
  border: 1px solid #6c6c6c;
  background: #fff;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 0;
  cursor: pointer;
  width: 100%;
  color: #000;
  margin-top: 24px;
  font-family: Outfit;

  :hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
`;

const WrapperInputTitle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`;

const WrapperSuccessSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`

const IconSuccess = styled.img`
  width: 60px;
  height: 60px;
`;

const InputTitle = styled.p`
  color: rgba(0, 0, 0, 0.93);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  font-family: Outfit;
`;

const Form = styled.form``;

export default ResetPasswordPage;
