import { FC } from "react";
import styled from "styled-components";

interface IQuestionComponent {
  content: string;
}

const UserQuestion: FC<IQuestionComponent> = ({ content }) => {
  return (
    <Wrapper>
      <BlankSpace />
      <WrapperChat>{content}</WrapperChat>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const BlankSpace = styled.div`
  flex: 1;
`;

const WrapperChat = styled.div`
  display: inline-flex;
  padding: 18px 19px 18px 22px;
  border-radius: 25px 0px 25px 25px;
  background: #3b5998;
  width: fit-content;
  color: #fff;
  font-family: "Plus Jakarta Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
`;

export default UserQuestion;
