import axios, { AxiosResponse } from "axios";
import { TypeDto } from "../models/transaction_type.model";

const TypeService = {
  getTypes(): Promise<AxiosResponse<{ data: TypeDto[] }>> {
    return axios.get("/transaction-types");
  },
  createType(name: string): Promise<AxiosResponse<{ data: TypeDto[] }>> {
    return axios.post("/transaction-types", { name });
  },
  deleteType(id: string) {
    return axios.delete(`/transaction-types/${id}`);
  },
  getType(id: string): Promise<AxiosResponse<{ data: TypeDto }>> {
    return axios.get(`/transaction-types/${id}`);
  },
  updateType(id: string, name: string) {
    return axios.put(`/transaction-types/${id}`, { name });
  },
};

export default TypeService;
