import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import styled from "styled-components";
import { useAppDispatch } from "../../store";
import {
  createSubscriptionThunk,
  getPaymentStatusThunk,
} from "../../store/actions/subscription.action";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import iconSuccess from "../../assets/checkoutSuccess.svg";
import iconFailed from "../../assets/checkoutFailed.svg";
import { getUserInfoThunk } from "../../store/actions/auth.action";

export const PAYMENT_MODE = ["Free Tier", "Standard Tier", "Premium Tier"];

export const PAYMENT_STATUS = {
  PENDING: 1,
  COMPLETE: 2,
  INCOMPLETE: 3,
};

const CheckoutForm = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { state } = location;
  const stripe = useStripe();
  const elements = useElements();
  const [status, setStatus] = useState<number>(0);
  const [isCardEmpty, setIsCardEmpty] = useState<boolean>(true);

  const createSubscription = async () => {
    try {
      // create a payment method
      const cardElement = elements?.getElement(CardElement);
      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: cardElement!,
      });

      if (!isCardEmpty) {
        setStatus(1);
      }

      const response = await dispatch(
        createSubscriptionThunk({
          paymentMethod: paymentMethod?.paymentMethod?.id || "",
          paymentMode: state.plan,
        })
      ).unwrap();

      const confirmPayment = await stripe?.confirmCardPayment(
        response.clientSecret
      );

      if (confirmPayment?.error) {
        alert(confirmPayment.error.message);
      } else {
        const idInterval = setInterval(async () => {
          try {
            const paymentStatusResponse = await dispatch(
              getPaymentStatusThunk({ paymentId: response.paymentId })
            ).unwrap();

            setStatus(paymentStatusResponse.status);

            if (
              paymentStatusResponse.status === 2 ||
              paymentStatusResponse.status === 3
            ) {
              clearInterval(idInterval);
              try {
                await dispatch(getUserInfoThunk()).unwrap();
              } catch (e) {}
            }
          } catch (e) {
            console.log(e);
          }
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const PaymentStep = (status: number) => {
    if (status === 1) {
      return (
        <ProcessingBtn>
          <CircularProgress
            color="inherit"
            size={20}
            style={{ marginRight: "8px" }}
          />
          Processing
        </ProcessingBtn>
      );
    }
    if (status === 2) {
      return (
        <SuccessBtn>
          <IconCheckout src={iconSuccess} alt="" />
          Success
        </SuccessBtn>
      );
    }

    if (status === 3) {
      return (
        <FailedBtn>
          <IconCheckout src={iconFailed} alt="" />
          Failed
        </FailedBtn>
      );
    }
  };

  return (
    <Wrapper>
      <WrapperLeftSide>
        <SubcribeText>Subcribe to {PAYMENT_MODE[state.plan - 1]}</SubcribeText>
        <WrapperPrice>
          <Price>${state.price}</Price>
          <Unit>per month</Unit>
        </WrapperPrice>
      </WrapperLeftSide>
      <WrapperForm>
        <FormTitle>Pay with card</FormTitle>
        <CardElement onChange={(event) => setIsCardEmpty(!event.complete)} />
        {!status ? (
          <PayBtn onClick={createSubscription}>Subscribe</PayBtn>
        ) : (
          PaymentStep(status)
        )}
      </WrapperForm>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-family: Poppins;
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  margin-top: 80px;
`;

const WrapperLeftSide = styled.div`
  width: 50%;
`;

const SubcribeText = styled.h3`
  margin: 0;
  padding: 0;
`;

const Price = styled.p`
  margin: 0;
  padding: 0;
  font-size: 32px;
  font-weight: bold;
  font-family: DM Sans;
  line-height: 32px;
  padding-right: 4px;
`;

const Unit = styled.p`
  margin: 0;
  padding: 0;
  color: gray;
`;

const WrapperPrice = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 14px;
`;

const WrapperForm = styled.div`
  flex: 1;
`;

const FormTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 24px;
  margin-bottom: 24px;
`;

const PayBtn = styled.button`
  border: none;
  width: 100%;
  background-color: #007fff;
  font-family: Outfit;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 12px 0;
  border-radius: 6px;
  margin-top: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
`;

const SuccessBtn = styled.button`
  border: none;
  width: 100%;
  background-color: #44cc46;
  font-family: Outfit;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 12px 0;
  border-radius: 6px;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProcessingBtn = styled.button`
  border: none;
  width: 100%;
  background-color: #007fff;
  font-family: Outfit;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 12px 0;
  border-radius: 6px;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FailedBtn = styled.button`
  border: none;
  width: 100%;
  background-color: #ff0000;
  font-family: Outfit;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 12px 0;
  border-radius: 6px;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconCheckout = styled.img`
  width: 22px;
  margin-right: 8px;
`;

export default CheckoutForm;
