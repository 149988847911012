import styled from "styled-components";
import MerchantDetailComponent from "../../components/MerchantDetail";
import AddedCard from "../../components/AddedCard";
import DashboardAdmin from "../../dashboard/Merchants";

const FileComponent = () => {
  return (
    <Wrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

export default FileComponent;
