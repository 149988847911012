// import { AuthUser } from '@app/common/types';
import jwtDecode from 'jwt-decode';

const acTokenKey = 'ac_token';
const rfTokenKey = 'rf_token';

export const getAcToken = () => {
  return localStorage.getItem(acTokenKey);
};

export const setAcToken = (token: string) => {
  localStorage.setItem(acTokenKey, token);
};

export const removeAcToken = () => {
  localStorage.removeItem(acTokenKey);
};

export const getRfToken = () => {
  return localStorage.getItem(rfTokenKey);
};

export const setRfToken = (token: string) => {
  localStorage.setItem(rfTokenKey, token);
};

export const removeRfToken = () => {
  localStorage.removeItem(rfTokenKey);
};

export const decodeToken = (token: string) => {
  return jwtDecode<any>(token);
};

export const getAuthUser = () => {
  const token = getAcToken();
  return token ? decodeToken(token) : null;
};
