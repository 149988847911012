import styled from "styled-components";

const SignalComponent = () => {
  return (
    <Wrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

export default SignalComponent;
