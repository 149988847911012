import axios, { AxiosResponse } from "axios";
import {
  MerchantDetailResponseDto,
  MerchantDto,
} from "../models/merchant.model";

const MerchantService = {
  getMerchants(
    weekly?: number
  ): Promise<AxiosResponse<{ data: MerchantDto[] }>> {
    return axios.get(`/shops/${weekly ? `?weekly=${weekly}` : ''}`);
  },
  addFavorite(id: string): Promise<AxiosResponse<{ data: MerchantDto }>> {
    return axios.post("/favorite-shop", { shopId: id });
  },
  getFavoriteMerchants(): Promise<AxiosResponse<{ data: MerchantDto[] }>> {
    return axios.get("/favorite-shop");
  },
  getMerchantDetail(
    id: string,
    units: string[] = ['mile', 'point', 'cash'],
  ): Promise<AxiosResponse<{ data: MerchantDetailResponseDto }>> {
    return axios.get(`/shops/detail/${id}?units=${units}`);
  },
  getMerchant(id: string): Promise<AxiosResponse<{ data: MerchantDto }>> {
    return axios.get(`/shops/${id}`);
  },
  createMerchant(
    name: string,
    image: File,
    transactionTypeId: string,
    mccCode: number,
    redirectUrl: string,
    discount: number,
    cashback: number
  ): Promise<AxiosResponse<{ data: MerchantDto }>> {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("image", image);
    formData.append("transactionTypeId", transactionTypeId);
    formData.append("mccCode", mccCode.toString());
    formData.append("redirectUrl", redirectUrl);
    formData.append("discount", discount.toString());
    formData.append("cashback", cashback.toString());
    return axios.post("/shops", formData);
  },
  deleteMerchant(id: string) {
    return axios.delete(`/shops/${id}`);
  },
  updateMerchant(
    id: string,
    data: {
      name: string;
      image: File;
      transactionTypeId: string;
      mccCode: number;
      redirectUrl: string;
      discount: number;
      cashback: number;
    }
  ) {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("image", data.image);
    formData.append("transactionTypeId", data.transactionTypeId);
    formData.append("mccCode", data.mccCode.toString());
    formData.append("redirectUrl", data.redirectUrl);
    formData.append("discount", data.discount.toString());
    formData.append("cashback", data.cashback.toString());
    return axios.put(`/shops/${id}`, formData);
  },
};

export default MerchantService;
