import PlanComponent from "../../components/Plan";
import { CheckOutlined, CloseOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAppSelector } from "../../store";

const PaymentScreen = () => {
  const navigate = useNavigate();
  const userPaymentMode = useAppSelector((state) => state.auth.userPaymentMode);
  const freeTierBenefits = [
    <>
      <SCheckOutlined color="success" /> Up to 3 cards and 10 search on the API
      for Mastercard on Mobile
    </>,
    <>
      <SCloseOutlined color="error" /> No Transactions & Activities
    </>,
    <>
      <SCloseOutlined color="error" /> Chatbot advisor not included
    </>,
  ];

  const standardTierBenefits = [
    <>
      <SCheckOutlined color="success" />
      Up to 5 cards and 25 search on the API for Mastercard on Mobile
    </>,
    <>
      <SCheckOutlined color="success" />
      Transactions & Activities included
    </>,
    <>
      <SCheckOutlined color="success" />
      Chatbot advisor included
    </>,
  ];

  const premiumTierBenefits = [
    <>
      <SCheckOutlined color="success" />
      Unlimited Cards and Unlimited search on the API for MasterCard on Mobile
    </>,
    <>
      <SCheckOutlined color="success" />
      All Features added
    </>,
    <>
      <SCheckOutlined color="success" />
      Chatbot advisor included
    </>,
  ];

  const handleSelectPlan = (price: number, plan: number) => {
    navigate("/checkout", { state: { price, plan } });
  };

  return (
    <Wrapper>
      <PlanComponent
        name="Free Tier"
        benefits={freeTierBenefits}
        isSubscribed
      />
      <PlanComponent
        name="Standard Tier"
        price={5.99}
        benefits={standardTierBenefits}
        onClick={() => handleSelectPlan(5.99, 2)}
        isSubscribed={userPaymentMode === 2}
      />
      <PlanComponent
        name="Premium Tier"
        price={8.99}
        benefits={premiumTierBenefits}
        onClick={() => handleSelectPlan(8.99, 3)}
        isSubscribed={userPaymentMode === 3}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 80px;
`;

const SCheckOutlined = styled(CheckOutlined)`
  padding-right: 4px;
`;

const SCloseOutlined = styled(CloseOutlined)`
  padding-right: 4px;
`;

export default PaymentScreen;
