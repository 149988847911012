/* eslint-disable max-len */
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  ADD_FAVORITE_MERCHANTS,
  CREATE_MERCHANT,
  DELETE_MERCHANT,
  GET_MERCHANT,
  GET_MERCHANTS,
  UPDATE_MERCHANT,
} from "./types";
import MerchantService from "../../services/merchant.service";

interface CreateMerchantParams {
  name: string;
  image: any;
  transactionTypeId: string;
  mccCode: number;
  redirectUrl: string;
  discount: number;
  cashback: number;
}

export const getMerchantsThunk = createAsyncThunk(
  GET_MERCHANTS,
  async (payload: { weekly?: number }, { rejectWithValue }) => {
    try {
      const res = await MerchantService.getMerchants(payload.weekly);
      return res.data.data;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const getFavoriteMerchantsThunk = createAsyncThunk(
  GET_MERCHANTS,
  async (payload, { rejectWithValue }) => {
    try {
      const res = await MerchantService.getFavoriteMerchants();
      return res.data.data;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const getMerchantThunk = createAsyncThunk(
  GET_MERCHANT,
  async (payload: { id: string }, { rejectWithValue }) => {
    try {
      const res = await MerchantService.getMerchant(payload.id);
      return res.data.data;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const getMerchantDetailThunk = createAsyncThunk(
  GET_MERCHANT,
  async (payload: { id: string, units?: string[] }, { rejectWithValue }) => {
    try {
      const res = await MerchantService.getMerchantDetail(payload.id, payload?.units);
      return res.data.data;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const createMerchantThunk = createAsyncThunk(
  CREATE_MERCHANT,
  async (payload: CreateMerchantParams, { rejectWithValue }) => {
    const {
      name,
      image,
      transactionTypeId,
      mccCode,
      redirectUrl,
      discount,
      cashback,
    } = payload;
    try {
      const res = await MerchantService.createMerchant(
        name,
        image,
        transactionTypeId,
        mccCode,
        redirectUrl,
        discount,
        cashback
      );
      return res.data.data;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const addFavoriteThunk = createAsyncThunk(
  ADD_FAVORITE_MERCHANTS,
  async (payload: { id: string }, { rejectWithValue }) => {
    const { id } = payload;
    try {
      const res = await MerchantService.addFavorite(id);
      return res.data.data;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const deleteMerchantThunk = createAsyncThunk(
  DELETE_MERCHANT,
  async (payload: { id: string }, { rejectWithValue }) => {
    try {
      await MerchantService.deleteMerchant(payload.id);
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const updateMerchantThunk = createAsyncThunk(
  UPDATE_MERCHANT,
  async (
    payload: { id: string; data: CreateMerchantParams },
    { rejectWithValue }
  ) => {
    try {
      await MerchantService.updateMerchant(payload.id, payload.data);
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);
