import {
  Alert,
  MenuItem,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { getUsedCardsThunk } from "../../store/actions/creditCard.action";
import { CardDto } from "../../models/card.model";
import { listCountries } from "../../constants/countries";
import SearchInput from "../../components/SearchInput";
import AddedCard from "../../components/AddedCard";
import noCreditCardImg from "../../assets/empty_card.svg";
import { getTransactionsThunk } from "../../store/actions/transaction.action";
import { EnumCurrency, EnumStatus } from "../../models/transaction.model";
import { DatePicker } from "antd";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

interface Column {
  id:
    | "shop"
    | "createdAt"
    | "amount"
    | "status"
    | "category"
    | "mcc"
    | "rewards";
  label: string;
  width?: string;
  align?: "right" | "center";
  component?: React.ReactNode;
}

const enumStatusText: Record<EnumStatus, string> = {
  [EnumStatus.Pending]: "Pending",
  [EnumStatus.Completed]: "Completed",
};

const enumCurencyIcon: Record<EnumCurrency, string> = {
  [EnumCurrency.USD]: "$",
  [EnumCurrency.EUR]: "€",
  [EnumCurrency.JPY]: "¥",
  [EnumCurrency.SGD]: "$",
};

const NoCardAdded = () => {
  return (
    <WrapperNoCreditCard>
      <NoAddedCreditCardImage src={noCreditCardImg} />
      <NoAddedCreditCardTitle>No credit cards selected</NoAddedCreditCardTitle>
      <NoAddedCreditCardSubtitle>
        Ready to unlock rewards? <br /> Add new cards now!
      </NoAddedCreditCardSubtitle>
    </WrapperNoCreditCard>
  );
};

const WalletComponent = () => {
  const { usedCards: listUsedCards } = useAppSelector(
    (state: RootState) => state.creditCard
  );
  const [usedCards, setUsedCards] = useState<CardDto[]>(listUsedCards);
  const [addedCards, setAddedCards] = useState<string[]>([]);
  const [countryCode, setCountryCode] = useState<string>("");
  const [selectedCard, setSelectedCard] = useState<string>("");
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [textToast, setTextToast] = useState<string>("");
  const [filterName, setFilerName] = useState<string>("");
  const [defaultFromDate, setDefaultFromDate] = useState<string>("");
  const [defaultToDate, setDefaultToDate] = useState<string>("");
  const [filterTextTransaction, setFilerTextTransaction] = useState<string>("");
  const [transactions, setTransactions] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<string[]>([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [debouncedSearchTermTransaction, setDebouncedSearchTermTransaction] =
    useState("");
  const [errorTransaction, setErrorTransaction] = useState("");
  const dispatch = useAppDispatch();
  const columns: readonly Column[] = [
    { id: "shop", label: "Name", width: "25%" },
    { id: "createdAt", label: "Date", width: "15%" },
    { id: "amount", label: "Amount", width: "10%", align: "center" },
    { id: "status", label: "Status", width: "15%", align: "center" },
    { id: "category", label: "Category", width: "15%", align: "center" },
    { id: "mcc", label: "MCC", width: "10%", align: "center" },
    {
      id: "rewards",
      label: "Rewards",
      width: "10%",
    },
  ];

  const fetchTransactions = async () => {
    try {
      const today = new Date();
      const oneWeekAgo = new Date(today);
      oneWeekAgo.setDate(today.getDate() - 7);

      const defaultFromDate = `${oneWeekAgo.getFullYear()}-${(
        oneWeekAgo.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${oneWeekAgo.getDate().toString().padStart(2, "0")}`;

      const defaultToDate = `${new Date().getFullYear()}-${(
        new Date().getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`;

      setDefaultFromDate(defaultFromDate);
      setDefaultToDate(defaultToDate);

      const res = await dispatch(
        getTransactionsThunk({
          fromDate: selectedDate[0] || defaultFromDate,
          toDate: selectedDate[1] || defaultToDate,
          keyword: debouncedSearchTermTransaction,
          userCardId: selectedCard,
        })
      ).unwrap();
      const data = res.map((item, index) => {
        const originalDate = new Date(item.createdAt);
        const formattedDate = `${(originalDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${originalDate
          .getDate()
          .toString()
          .padStart(2, "0")}-${originalDate.getFullYear()}`;

        return {
          shop: item.shop,
          createdAt: formattedDate,
          amount: item.amount,
          status: enumStatusText[item.status],
          category: item.category.name,
          mcc: item.shop.mccCode,
          rewards: item.reward,
          currency: enumCurencyIcon[item.currency],
        };
      });
      setTransactions(data);
    } catch (exception: any) {
      setErrorTransaction(exception.message);
    }
  };

  const fetchData = async () => {
    try {
      const resUsedCards = await dispatch(
        getUsedCardsThunk({
          countryCode: countryCode,
          name: debouncedSearchTerm,
        })
      ).unwrap();
      setUsedCards(resUsedCards);
    } catch (exception: any) {}
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setDebouncedSearchTerm(filterName);
    }, 500);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [filterName]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setDebouncedSearchTermTransaction(filterTextTransaction);
    }, 500);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [filterTextTransaction]);

  useEffect(() => {
    fetchData();
  }, [countryCode, debouncedSearchTerm]);

  useEffect(() => {
    fetchTransactions();
  }, [selectedCard, debouncedSearchTermTransaction, selectedDate]);

  useEffect(() => {
    setUsedCards(listUsedCards);
  }, [listUsedCards]);

  const handleDateChange = (date: any, dateString: any) => {
    if (date && date.length > 0) {
      const rangeDate = date.map((item: any) => {
        const originalDate = new Date(item);
        const formattedDate = `${originalDate.getFullYear()}-${(
          originalDate.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${originalDate
          .getDate()
          .toString()
          .padStart(2, "0")}`;
        return formattedDate;
      });
      setSelectedDate(rangeDate);
    } else {
      setSelectedDate([]);
    }
  };

  const onChange = async (cardId: string) => {
    const tempCard = [...addedCards];
    const index = addedCards.findIndex((item) => item === cardId);

    if (index === -1) {
      tempCard.push(cardId);
    } else {
      tempCard.splice(index, 1);
    }
    setAddedCards(tempCard);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const handleChangeCountry = async (
    event: React.ChangeEvent<{ value: string }>
  ) => {
    setCountryCode(event.target.value === "all" ? "" : event.target.value);
  };

  const refetchData = (idCard: string) => {
    setTextToast("The card has been successfully deleted!");
    setOpenToast(true);
    fetchData();
    const tempCard = [...addedCards];
    const index = addedCards.findIndex((item) => item === idCard);
    tempCard.splice(index, 1);
    setAddedCards(tempCard);
  };

  const handleSelectCard = async (
    event: React.ChangeEvent<{ value: string }>
  ) => {
    setSelectedCard(event.target.value === "all" ? "" : event.target.value);
  };

  const handleChangeSearchInput = (value: string) => {
    setFilerName(value);
  };

  const handleChangeSearchInputTransactions = (value: string) => {
    setFilerTextTransaction(value);
  };

  return (
    <Wrapper>
      <Title>Your Cards</Title>
      <WrapperTabContent>
        <WarpperFilter>
          <WrapperFilterInput>
            <SearchInput
              handleChange={handleChangeSearchInput}
              placeholder="Search for cards"
            />
          </WrapperFilterInput>
          {usedCards?.length !== 0 && (
            <WrapperFilterInput>
              <TextField
                select
                defaultValue={"null"}
                fullWidth
                inputProps={{
                  sx: {
                    padding: "12px",
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    zIndex: "999",
                    backgroundColor: "#fff",
                  },
                }}
                onChange={handleChangeCountry}
              >
                <MenuItem value="null" disabled style={{ display: "none" }}>
                  Please select your country
                </MenuItem>
                <MenuItem value="all">Show all cards</MenuItem>
                {listCountries?.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </WrapperFilterInput>
          )}
        </WarpperFilter>
        <WrapperListCard>
          {usedCards?.length > 0 &&
            usedCards?.map((item, index) => (
              <AddedCard
                idCard={item._id}
                key={index}
                imgSrc={item.logoUrl}
                name={item.name}
                onChange={() => onChange(item._id)}
                isUsed={true}
                refetchData={refetchData}
              />
            ))}
          {usedCards?.length === 0 && <NoCardAdded />}
        </WrapperListCard>
      </WrapperTabContent>
      <Title>Transactions</Title>
      <WrapperTabContent>
        <WarpperFilter>
          <WrapperFilterInputTransaction>
            <TextField
              select
              defaultValue={"null"}
              fullWidth
              inputProps={{
                sx: {
                  padding: "12px",
                },
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  zIndex: "999",
                  backgroundColor: "#fff",
                },
              }}
              onChange={handleSelectCard}
            >
              <MenuItem value="null" disabled style={{ display: "none" }}>
                Choose a card to view transactions
              </MenuItem>
              <MenuItem value="all">Show all cards</MenuItem>
              {usedCards?.map((item, index) => (
                <MenuItem key={index} value={item._id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </WrapperFilterInputTransaction>
          <WrapperFilterInputTransaction>
            <SearchInput
              handleChange={handleChangeSearchInputTransactions}
              placeholder="Search for anything"
            />
          </WrapperFilterInputTransaction>
          <WrapperFilterInputTransaction>
            {defaultFromDate && defaultToDate && (
              <StyledDatePicker
                format={"D-MMM"}
                onChange={handleDateChange}
                defaultValue={[
                  dayjs(defaultFromDate || "", { format: "YYYY-MM-DD" }),
                  dayjs(defaultToDate, { format: "YYYY-MM-DD" }),
                ]}
              />
            )}
          </WrapperFilterInputTransaction>
        </WarpperFilter>
        <WrapperListCard>
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <TableContainer sx={{ maxHeight: 600 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        key={index}
                        align={column.align}
                        style={{
                          width: column.width,
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.map((transaction, rIndex) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={rIndex}
                      >
                        {columns.map((column, index) => {
                          const value = transaction[column.id];
                          return (
                            <TableCell key={index} align={column.align}>
                              {column.id === "shop" ? (
                                <WrapperShopInfo>
                                  <img
                                    style={{ width: "80%" }}
                                    src={transaction.shop.logoUrl}
                                    alt=""
                                  />
                                  {transaction.shop.name}
                                </WrapperShopInfo>
                              ) : column.id === "status" ? (
                                <Status status={value}>{value}</Status>
                              ) : column.id === "amount" ? (
                                <>
                                  {transaction["currency"]}
                                  {value}
                                </>
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <TablePagination
              rowsPerPageOptions={[5, 10]}
              component='div'
              count={listCards.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </Paper>
          {errorTransaction ? (
            <Error>{errorTransaction}</Error>
          ) : (
            transactions?.length === 0 && <NoData>No Data</NoData>
          )}
        </WrapperListCard>
      </WrapperTabContent>
      <Snackbar
        open={openToast}
        autoHideDuration={2000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseToast}
          severity="success"
          sx={{ width: "100%" }}
        >
          {textToast}
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Title = styled.h1`
  color: #fff;
  font-family: DM Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  margin-bottom: 30px;
`;

const WrapperTabContent = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  border: 0.25px solid rgba(109, 125, 147, 0.15);
  border-radius: 12px;
  padding: 30px 40px;
  margin-bottom: 30px;
  overflow-x: hidden;
`;

const WrapperFilterInput = styled.div`
  width: 50%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const WrapperFilterInputTransaction = styled.div`
  width: 33%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const WarpperFilter = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  gap: 24px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const WrapperListCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  overflow: auto;
  max-height: 500px;

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c1c1c1;
  }
`;

const WrapperNoCreditCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 25px;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

const NoAddedCreditCardImage = styled.img``;

const NoAddedCreditCardTitle = styled.p`
  margin: 0;
  color: #4a4a4a;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const NoAddedCreditCardSubtitle = styled.p`
  margin: 0;
  color: #4a4a4a;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const WrapperShopInfo = styled.div`
  display: flex;
  font-family: Poppins;

  img {
    width: 30px !important;
    margin-right: 8px;
    object-fit: contain;
  }
`;

const Status = styled.div<{ status: string }>`
  background-color: ${(props) =>
    props.status === enumStatusText[1] ? "#fdcccc" : "#abdbb6"};
  color: ${(props) =>
    props.status === enumStatusText[1] ? "#ff5050" : "#2d9645"};
  padding: 2px 12px;
  border-radius: 12px;
`;

const StyledDatePicker = styled(RangePicker)`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    background-color: transparent !important;
    border: 1px solid #a4a4a4;
    color: #000;
    border-radius: 10px;
    font-family: Poppins;
    padding: 12px;
  `}
`;

const NoData = styled.div`
  width: 100%;
  text-align: center;
  color: gray;
  font-family: Poppins;
`;

const Error = styled.div`
  width: 100%;
  text-align: center;
  color: red;
  font-family: Poppins;
  font-weight: bold;
`;

export default WalletComponent;
