import React, { FC, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as SaveIcon } from "../../assets/star.svg";
import { ReactComponent as SavedIcon } from "../../assets/saved.svg";
import { MileItem, PointItem, RebateItem } from "../MileRebate";
import { Checkbox, FormControlLabel } from "@mui/material";
import {
  MerchantDetailResponseDto,
  MerchantDto,
} from "../../models/merchant.model";
import {
  AuthStepContext,
  AuthStepContextType,
} from "../../context/authStep";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useAppDispatch } from "../../store";
import {
  addFavoriteThunk,
  getMerchantDetailThunk
} from "../../store/actions/merchant.action";

interface IBestCardComponent {
  value: string;
  unit: string;
  card: string;
  name: string;
  btnText: string;
  shopLink?: string;
  urlHomePage?: string;
  openAddCard?: () => void;
}

interface IMerchantDetailComponent {
  data: MerchantDetailResponseDto;
  openAddCard?: () => void;
  handleSuccess?: () => void;
}

const BestCardComponent: FC<IBestCardComponent> = ({
  value,
  unit,
  card,
  name,
  btnText,
  shopLink,
  openAddCard,
  urlHomePage,
}) => {
  const handleClick = () => {
    openAddCard && openAddCard();
  };
  const { handleSetStep } = useContext(AuthStepContext) as AuthStepContextType;
  const getItem = (value: string, unit: string) => {
    switch (unit) {
      case 'mile':
        return (<MileItem
          getBonus={parseFloat(value) ? true : false}
          value={value}
        />)
      case 'cash':
        return (<RebateItem
          getBonus={parseFloat(value) ? true : false}
          value={value}
        />)
      default:
        return (<PointItem
        getBonus={parseFloat(value) ? true : false}
        value={value}
      />)
    }
  }

  return (
    <BestCard>
      <Card src={card} />
      <WrapperInfo>
        <BestCardText>{name}</BestCardText>
        {getItem(value, unit)}
      </WrapperInfo>
      {shopLink ? (
        <ShopButton href={shopLink} target="_blank">
          {btnText}
        </ShopButton>
      ) : (
        <Apply target="_blank" href={urlHomePage}>
          {btnText}
        </Apply>
      )}
    </BestCard>
  );
};

const MerchantDetailComponent: FC<IMerchantDetailComponent> = ({
  data,
  openAddCard,
  handleSuccess,
}) => {
  const [shopData, setShopData] = useState<MerchantDetailResponseDto>(data);
  const [units, setUnits] = useState<string[]>(['mile','point','cash']);
  const dispatch = useAppDispatch();

  const handleClickMerchant = async (merchantId: string) => {
    const res = await dispatch(
      getMerchantDetailThunk({ id: merchantId })
    ).unwrap();
    setShopData(res);
  };

  const handleSave = async () => {
    try {
      if (!shopData.favoriteShop) {
        await dispatch(addFavoriteThunk({ id: data.shop?._id || "" })).unwrap();
        const res = await dispatch(
          getMerchantDetailThunk({ id: data.shop?._id || "" })
        ).unwrap();
        setShopData(res)
        handleSuccess && handleSuccess();
      }
    } catch { }
  };

  useEffect(() => {
    setShopData(data);
  }, [data]);

  const handleClickCheckbox = async (e: any, unit: string) => {
    const new_units = e.target.checked ? [unit, ...units] : units.filter((v) => (v !== unit))
    setUnits(new_units)
    
    const res = await dispatch(
      getMerchantDetailThunk({ id: shopData.shop?._id || "" , units: new_units })
    ).unwrap();
    setShopData(res)
  }

  return (
    shopData && (
      <Wrapper>
        <WrapperLeftSide>
          <Tiltle>{shopData.shop?.name}</Tiltle>
          <WrapperMerchant>
            <WrapperSaveIcon>
              {shopData.favoriteShop ? (
                <SavedIcon width={25} onClick={handleSave} />
              ) : (
                <SaveIcon width={25} height={22} onClick={handleSave} />
              )}
            </WrapperSaveIcon>
            <WrapperImage>
              <Image src={shopData.shop?.logoUrl} />
            </WrapperImage>
          </WrapperMerchant>
          <Others>Similar Merchants</Others>
          <div style={{ width: "100%" }}>
            <Swiper
              spaceBetween={20}
              slidesPerView={1.8}
              slidesPerGroup={1}
              style={{
                width: "400px",
                paddingBottom: "8px",
                background: "#fff",
              }}
            >
              {shopData.similarShop?.map((item: MerchantDto) => (
                <SwiperSlide>
                  <SimilarMerchantCard
                    onClick={() => handleClickMerchant(item._id)}
                  >
                    <SimilarMerchantImage src={item.logoUrl} />
                  </SimilarMerchantCard>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </WrapperLeftSide>
        <WrapperRightSide>
          <WrapperMerchantId>
            <MerchantId>
              MCC - {shopData.shop?.mccCode} • {shopData.shop?.name}
            </MerchantId>
          </WrapperMerchantId>
          <WrapperBestCard>
            <WrapperCheckbox>
              <SFormControlLabel
                control={<SCheckbox 
                  onChange={(e) => {handleClickCheckbox(e, 'mile')}} 
                  defaultChecked
                />}
                label="Miles"
              />
              <SFormControlLabel
                control={<SCheckbox 
                  onChange={(e) => {handleClickCheckbox(e, 'point')}} 
                  defaultChecked
                />}
                label="Points"
              />
              <SFormControlLabel
                control={<SCheckbox 
                  onChange={(e) => {handleClickCheckbox(e, 'cash')}} 
                  defaultChecked
                />}
                label="Cashback"
              />
            </WrapperCheckbox>
            <BestCardText>Your Best Card To Use</BestCardText>
            {shopData.yourBestCards?.map((yourCard) => (
              <BestCardComponent
                name={yourCard.card.name}
                card={yourCard.card.logoUrl}
                value={yourCard.point.toString()}
                unit={yourCard.unit?.toString()}
                btnText="Shop Now"
                shopLink={shopData.shop?.redirectUrl}
              />
            ))}
            <></>
          </WrapperBestCard>
          <WrapperBestCard>
            <BestCardText>Best Card To Use</BestCardText>
            {shopData.bestCardsToUse?.map((bestCard) => (
              <BestCardComponent
                name={bestCard.card.name}
                card={bestCard.card.logoUrl}
                value={bestCard.point.toString()}
                urlHomePage={bestCard.card.urlCardHomePage}
                unit={bestCard.unit?.toString()}
                btnText="Apply Now"
              // openAddCard={openAddCard}
              />
            ))}
          </WrapperBestCard>
        </WrapperRightSide>
      </Wrapper>
    )
  );
};

const Wrapper = styled.div`
  width: fit-content;
  display: flex;
  gap: 32px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.background};

  @media (max-width: 900px) {
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

const WrapperLeftSide = styled.div``;

const WrapperRightSide = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  gap: 24px;
  align-items: center;

  @media (max-width: 900px) {
    padding-top: 24px;
  }
`;

const Tiltle = styled.h1`
  color: #000;
  text-align: center;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  margin-bottom: 18px;

  @media (max-width: 900px) {
    font-size: 24px;
  }
`;

const WrapperMerchant = styled.div`
  position: relative;
  padding: 134px 32px;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(93, 120, 190, 0.06) 0%,
    rgba(93, 120, 190, 0.09) 100%
  );

  @media (max-width: 900px) {
    padding: 32px;
  }
`;

const WrapperSaveIcon = styled.div`
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const WrapperImage = styled.div`
  border-radius: 8px;
  background-color: #fff;
  padding: 16px;
  box-sizing: border-box;
`;

const Image = styled.img`
  width: 100%;
`;

const WrapperMerchantId = styled.div`
  display: flex;
  padding: 12px 24px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  margin: 0;
  border-radius: 10px;
  background: linear-gradient(
    210deg,
    rgba(0, 127, 255, 0.5) 13.4%,
    #5799f7 86.6%
  );
`;

const MerchantId = styled.p`
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;

  @media (max-width: 900px) {
    font-size: 14px;
  }
`;

const BestCardText = styled.p`
  color: #000;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;

  @media (max-width: 900px) {
    font-size: 14px;
  }
`;

const WrapperCheckbox = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 12px;
`;

const SFormControlLabel = styled(FormControlLabel)({
  "&.MuiFormControlLabel-root": {
    margin: 0,
    ".MuiTypography-root": {
      fontFamily: "Poppins",
    },
  },
});

const WrapperBestCard = styled.div`
  width: 100%;
`;

const BestCard = styled.div`
  border-radius: 10px;
  background: #eceff1;
  padding: 20px;
  display: flex;
  gap: 12px;
  min-width: 540px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 8px;
  position: relative;

  @media (max-width: 900px) {
    flex-direction: column;
    min-width: unset;
  }
`;

const Card = styled.img`
  width: 125px;
`;

const WrapperInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const SCheckbox = styled(Checkbox)`
  padding: 0 !important;
  padding-right: 8px !important;
`;

const ApplyButton = styled.button`
  border-radius: 100px;
  background: linear-gradient(0deg, #3369ff 0%, #5799f7 100%);
  position: absolute;
  border: none;
  color: #fff;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding: 4px 12px;
  cursor: pointer;
  bottom: 8px;
  right: 8px;
`;

const Apply = styled.a`
  border-radius: 100px;
  background: linear-gradient(0deg, #3369ff 0%, #5799f7 100%);
  position: absolute;
  border: none;
  color: #fff;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding: 4px 12px;
  cursor: pointer;
  bottom: 8px;
  right: 8px;
  text-decoration: none;

  @media (max-width: 900px) {
    font-size: 14px;
  }
`;

const ShopButton = styled.a`
  border-radius: 100px;
  background: linear-gradient(0deg, #f83a44 0%, #f86b6b 100%);
  position: absolute;
  border: none;
  color: #fff;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding: 4px 12px;
  cursor: pointer;
  bottom: 8px;
  right: 8px;
  text-decoration: none;
`;

const Others = styled.p`
  color: #000;
  text-align: center;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 8px 0;
`;

const WrapperSimilarMerchantCard = styled.div`
  display: flex;
  width: 346px;
  overflow: scroll;
  gap: 20px;
  padding: 4px 0;

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
  }

  ::-webkit-scrollbar {
    background-color: #f5f5f5;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c1c1c1;
  }
`;

const SimilarMerchantCard = styled.div`
  height: 74px;
  min-width: 180px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 22px;
  padding: 8px;
  box-sizing: border-box;
  object-fit: cover;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

const SimilarMerchantImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

export default MerchantDetailComponent;
