import styled from "styled-components";
import getStartedImage from "../../assets/get_started.png";
import { useContext } from "react";
import {
  AuthStepContext,
  AuthStepContextType,
  AuthStepEnum,
} from "../../context/authStep";

const GetStartedComponent = () => {
  const { handleSetStep } = useContext(AuthStepContext) as AuthStepContextType;

  return (
    <Wrapper>
      <WrapperContent>
        <Title>Your Rewards Your Way</Title>
        <Description>
          Maximize your credit card benefits with Cash Miles! Discover the best
          card for each purchase and stay updated on merchant deals. Join us in
          revolutionizing your financial journey.
        </Description>
        <WrapperButtom>
          <Button onClick={() => handleSetStep(AuthStepEnum.REGISTER)}>
            Sign up
          </Button>
          <Button onClick={() => handleSetStep(AuthStepEnum.LOGIN)}>
            Login
          </Button>
        </WrapperButtom>
      </WrapperContent>
      <Image src={getStartedImage} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Image = styled.img`
  height: 444px;

  @media (max-width: 900px) {
    display: none;
  }
`;

const Title = styled.h1`
  color: #16294a;
  font-family: Poppins;
  font-size: 63px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  margin: 0;
  margin-bottom: 40px;

  @media (max-width: 900px) {
    font-size: 32px;
    margin-bottom: 20px;
  }
`;

const Description = styled.p`
  color: rgba(22, 41, 74, 0.4);
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  margin: 0;

  @media (max-width: 900px) {
    font-size: 18px;
  }
`;

const WrapperButtom = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 24px;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 12px;
  }
`;

const Button = styled.button`
  border-radius: 10px;
  background: #007fff;
  border: none;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 25px;
  min-width: 188px;
  cursor: pointer;
  font-family: Outfit;

  :hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
`;

export default GetStartedComponent;
