import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../store';

interface IPrivateRoutesProps {
  Layout: React.FC;
}

const PrivateRoutes = ({ Layout }: IPrivateRoutesProps) => {
  const authState = useAppSelector((state) => state.auth);

  return !authState.me?.isAdmin ? (
    <Navigate to='/'/>
  ) : (
    <Layout />
  );
};

export default PrivateRoutes;
