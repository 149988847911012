/* eslint-disable max-len */
import axios, { AxiosResponse } from "axios";
import { CardDto } from "../models/card.model";

const CreditCardService = {
  addUserCard(
    cardIds: string[]
  ): Promise<
    AxiosResponse<{ data: { message: string; [key: string]: string } }>
  > {
    return axios.post("/user-cards", { cardIds });
  },
  getUsedCards(
    countryCode?: string,
    name?: string
  ): Promise<AxiosResponse<{ data: CardDto[] }>> {
    return axios.get(`/user-cards?countryCode=${countryCode}&name=${name}`);
  },
  getUnUsedCards(
    countryCode?: string,
    name?: string
  ): Promise<AxiosResponse<{ data: CardDto[] }>> {
    return axios.get(`/cards/un-use?countryCode=${countryCode}&name=${name}`);
  },
  getCards(): Promise<AxiosResponse<{ data: CardDto[] }>> {
    return axios.get("/cards");
  },
  createCard(
    name: string,
    image: File,
    defaultPoint: number,
    rate: number,
    countryCode: string,
    urlHomePage: string
  ): Promise<AxiosResponse<{ data: CardDto }>> {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("image", image);
    formData.append("defaultPoint", defaultPoint.toString());
    formData.append("rate", rate.toString());
    formData.append("countryCode", countryCode.toString());
    formData.append("urlCardHomePage", urlHomePage.toString());
    return axios.post("/cards", formData);
  },
  getCard(id: string): Promise<AxiosResponse<{ data: CardDto }>> {
    return axios.get(`/cards/${id}`);
  },
  deleteCard(id: string) {
    return axios.delete(`/cards/${id}`);
  },
  removeUserCard(id: string) {
    return axios.delete(`/user-cards/${id}`);
  },
  updateCard(
    id: string,
    data: {
      name: string;
      image: File;
      defaultPoint: number;
      rate: number;
      countryCode: string;
      urlHomePage: string;
    }
  ) {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("image", data.image);
    formData.append("defaultPoint", data.defaultPoint.toString());
    formData.append("rate", data.rate.toString());
    formData.append("countryCode", data.countryCode.toString());
    formData.append("urlCardHomePage", data.urlHomePage.toString());
    return axios.put(`/cards/${id}`, formData);
  },
};

export default CreditCardService;
