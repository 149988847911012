/* eslint-disable max-len */
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import AuthService from "../../services/auth.service";
import { CREATE_QUESTION, GET_ANSWER, GET_HISTORY } from "./types";
import ChatService from "../../services/chat.service";

export const createQuestionThunk = createAsyncThunk(
  CREATE_QUESTION,
  async (payload: { question: string }, { rejectWithValue }) => {
    try {
      const response = await ChatService.createQuestion(payload.question);

      return response.data.data;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const getHistoryThunk = createAsyncThunk(
  GET_HISTORY,
  async (payload: { page: number }, { rejectWithValue }) => {
    try {
      const response = await ChatService.getHistory(payload.page);

      return response.data.data;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const getAnswerThunk = createAsyncThunk(
  GET_ANSWER,
  async (payload: { id: string }, { rejectWithValue }) => {
    try {
      const response = await ChatService.getAnswer(payload.id);

      return response.data.data;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);
