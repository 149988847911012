const theme = {
  colors: {
    primary: '#3B5998CC',
    secondary: '#2ecc71',
    text: "#3B5998",
    background: '#D0D7E8',
  },
};

export default theme;
