import axios, { AxiosResponse } from 'axios';
import { TransactionDto } from '../models/transaction.model';

const TransactionService = {
  getTransactions(
    fromDate: string,
    toDate: string,
    keyword?: string,
    userCardId?: string
  ): Promise<AxiosResponse<{ data: TransactionDto[] }>> {
    return axios.get(
      `/transaction?fromDate=${fromDate}&toDate=${toDate}${
        keyword ? `&keyword=${keyword}` : ''
      }${userCardId ? `&userCardId=${userCardId}` : ''}`
    );
  },
};

export default TransactionService;
