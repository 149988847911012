import styled from "styled-components";
import card from "../../assets/cards/american_express.png";
import { Checkbox } from "@mui/material";
import { FC, useState } from "react";
import deleteIcon from "../../assets/trash.svg";
import { useAppDispatch } from "../../store";
import {
  getAllUserCardsThunk,
  removeUserCardThunk,
} from "../../store/actions/creditCard.action";

interface ICard {
  imgSrc: string;
  name: string;
  isUsed?: boolean;
  isChecked?: boolean;
  idCard: string;
  onChange?: () => void;
  refetchData?: (idCard: string) => void;
}

const AddedCard: FC<ICard> = ({
  imgSrc,
  name,
  isUsed,
  idCard,
  isChecked,
  onChange,
  refetchData,
}) => {
  const dispatch = useAppDispatch();

  const handleChange = () => {
    onChange && onChange();
  };

  const handleDeleteCard = async () => {
    try {
      await dispatch(removeUserCardThunk({ id: idCard })).unwrap();
      refetchData && refetchData(idCard);
      await dispatch(getAllUserCardsThunk()).unwrap();
    } catch (e) {}
  };

  return (
    <Wrapper isChecked={isChecked || false}>
      <WrapperInfo
        onClick={() => {
          !isUsed && handleChange();
        }}
      >
        <CardImg src={imgSrc} />
        <CardName>{name}</CardName>
      </WrapperInfo>
      {isUsed && <Icon src={deleteIcon} onClick={() => handleDeleteCard()} />}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isChecked: boolean }>`
  border-radius: 10px;
  border: ${(props) =>
    props.isChecked === true
      ? "1px solid #3369FF"
      : "0.25px solid rgba(109, 125, 147, 0.15)"};
  background: ${(props) =>
    props.isChecked === true ? "rgba(51, 105, 255, 0.50)" : "#ECEFF1"};
  color: ${(props) => (props.isChecked === true ? "#fff" : "#000")};
  padding: 8px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
`;

const WrapperInfo = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const CardImg = styled.img`
  width: 112px;
  margin-right: 30px;

  @media (max-width: 900px) {
    margin-right: 0;
    margin-bottom: 4px;
  }
`;

const CardName = styled.p`
  margin: 0;
  text-align: center;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (max-width: 900px) {
    font-size: 14px;
  }
`;

const SCheckbox = styled(Checkbox)`
  padding: 0 !important;
  padding-right: 8px !important;
`;

const Icon = styled.img`
  width: 20px;
  cursor: pointer;
`;

export default AddedCard;
