/* eslint-disable max-len */
import axios, { AxiosResponse } from "axios";

const AuthService = {
  login(
    email: string,
    password: string
  ): Promise<
    AxiosResponse<{ data: { message: string; [key: string]: string } }>
  > {
    return axios.post("/user/login", { email, password });
  },
  register(
    email: string,
    firstName: string,
    lastName: string,
    password: string
  ): Promise<
    AxiosResponse<{
      data: { token: string; refresh_token: string; [key: string]: string };
    }>
  > {
    return axios.post("/user/create", { email, firstName, lastName, password });
  },
  verifyCode(
    email: string,
    code: string
  ): Promise<
    AxiosResponse<{
      data: { token: string; refreshToken: string; [key: string]: string };
    }>
  > {
    return axios.post("/user/verify-code", { email, code });
  },
  forgotPassword(email: string) {
    return axios.post("/user/forget-password", { email });
  },
  resetPassword(password: string, token: string) {
    return axios.post(`/user/reset-password/${token}`, { password });
  },
  getUserInfo() {
    return axios.get("user/info");
  },
};

export default AuthService;
