import { PreloadedState } from "@reduxjs/toolkit";
import rootReducer from "../store/reducers";
import { AuthState } from "../store/reducers/auth.reducer";

type StateType = PreloadedState<ReturnType<typeof rootReducer>>;
const AUTH_LS_KEY = '__INTERNAL__';

export const loadState = (): StateType | undefined => {
	try {
		const serializedState = localStorage.getItem('AUTH');
		if (serializedState === null) {
			return undefined;
		}
		const serializedJson = atob(serializedState);
		const json = JSON.parse(serializedJson);
		return {
			auth: {
				...json.auth,
				revoked: false,
			},
			// user: {
			// 	...initialState,
			// },
		} as unknown as StateType;
	} catch (err) {
		return undefined;
	}
};

export const saveState = (state: { auth: AuthState }): void => {
	try {
		const serializedState = JSON.stringify(state.auth.accessToken);
		const base64 = btoa(serializedState);
		localStorage.setItem('AUTH', base64);
	} catch {
		//ignore write errors
	}
};

export const removeState = (): void => {
	try {
		localStorage.removeItem('AUTH')
		localStorage.removeItem('ac_token')
		localStorage.removeItem('rf_token')
		localStorage.setItem('isAuthenticated', 'false');
	} catch {
		//ignore write errors
	}
};

export const saveAT = (accessToken: string): void => {
	try {
		localStorage.setItem('ac_token', accessToken);
		localStorage.setItem('isAuthenticated', 'true');
	} catch {
		//ignore write errors
	}
};