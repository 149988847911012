import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/index";
import "./index.css";
import AuthStepProvider from "./context/authStep";
import React from "react";
import { ThemeProvider } from "styled-components";
import theme from "./context/theme";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const key =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_STRIPE_KEY_PROD
    : process.env.REACT_APP_STRIPE_KEY_TEST;
const rootElement = document.getElementById("root");
const stripePromise = loadStripe(key!);

ReactDOM.render(
  // <React.StrictMode>
  <Elements stripe={stripePromise}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Provider store={store}>
          <AuthStepProvider>
            <App />
          </AuthStepProvider>
        </Provider>
      </BrowserRouter>
    </ThemeProvider>
  </Elements>,
  // </React.StrictMode>,
  rootElement
);
