import { InputAdornment, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import { FC } from "react";

interface ISearchInput {
  placeholder: string;
  handleChange?: (value: string) => void;
}

const SearchInput: FC<ISearchInput> = ({ placeholder, handleChange }) => {
  return (
    <TextField
      type={"text"}
      fullWidth
      placeholder={placeholder}
      sx={{ border: "none", "& fieldset": { border: "none" } }}
      onChange={(e) => {
        handleChange && handleChange(e.target.value)
      }}
      inputProps={{
        sx: {
          padding: "12px",
        },
      }}
      InputProps={{
        style: {
          borderRadius: "10px",
          backgroundColor: "rgba(109, 125, 147, 0.10)",
          color: "#6D7D93",
          fontFamily: "DM Sans",
        },
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchInput;
