/* eslint-disable max-len */
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import AuthService from "../../services/auth.service";
import {
  FORGOT_PASSWORD,
  GET_USER_INFO,
  LOGIN,
  LOGOUT,
  REGISTER,
  RESET_PASSWORD,
  VERIFY_CODE,
} from "./types";

export const loginThunk = createAsyncThunk(
  LOGIN,
  async (payload: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const response = await AuthService.login(payload.email, payload.password);

      return {
        message: response.data.data.message,
        email: payload.email,
        password: payload.password,
        token: response.data.data.token,
        refreshToken: response.data.data.refreshToken
      };
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const registerThunk = createAsyncThunk(
  REGISTER,
  async (
    payload: {
      email: string;
      firstName: string;
      lastName: string;
      password: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await AuthService.register(
        payload.email,
        payload.firstName,
        payload.lastName,
        payload.password
      );

      return {
        token: response.data.data.token,
        refreshToken: response.data.data.refreshToken,
      };
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const verifyCode = createAsyncThunk(
  VERIFY_CODE,
  async (payload: { email: string; code: string }, { rejectWithValue }) => {
    try {
      const response = await AuthService.verifyCode(
        payload.email,
        payload.code
      );

      return {
        token: response.data.data.token,
        refreshToken: response.data.data.refreshToken,
        firstName: response.data.data.firstName,
        lastName: response.data.data.lastName,
        email: response.data.data.email,
      };
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const forgotPassword = createAsyncThunk(
  FORGOT_PASSWORD,
  async (payload: { email: string }, { rejectWithValue }) => {
    try {
      await AuthService.forgotPassword(payload.email);
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const resetPassword = createAsyncThunk(
  RESET_PASSWORD,
  async (payload: { password: string; token: string }, { rejectWithValue }) => {
    try {
      await AuthService.resetPassword(payload.password, payload.token);
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const getUserInfoThunk = createAsyncThunk(
  GET_USER_INFO,
  async (payload, { rejectWithValue }) => {
    try {
      const res = await AuthService.getUserInfo();
      return res.data.data;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const logout = createAction(LOGOUT, (): any => {
  return {};
});
