import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import { Button, FormHelperText, MenuItem, Select } from "@mui/material";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { TypeDto } from "../../models/transaction_type.model";
import {
  createCardThunk,
  updateCardThunk,
} from "../../store/actions/creditCard.action";
import { useAppDispatch } from "../../store";
import { listCountries } from "../../constants/countries";

type FormValues = {
  id: string;
  name: string;
  logo: any;
  defaultPoint: number;
  rate: number;
  country: string;
  urlHomePage: string;
};

const schema = yup.object({
  name: yup.string().required("Name is required!"),
  country: yup.string().required("Country is required!"),
  urlHomePage: yup.string().required("URL is required!"),
  rate: yup
    .number()
    .typeError("Mile must be a number!")
    .required("Mile is required!"),
  defaultPoint: yup
    .number()
    .typeError("Point must be a number!")
    .required("Point is required!"),
});

interface IFormCard {
  data?: FormValues;
  refetchData?: () => void;
  handleSuccess?: () => void;
}

const FormCard: FC<IFormCard> = ({
  data = undefined,
  refetchData,
  handleSuccess,
}) => {
  const [error, setError] = useState<string>("");
  const [image, setImage] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  const onSubmit = async (dataForm: FormValues) => {
    if (data) {
      await dispatch(
        updateCardThunk({
          id: data.id,
          data: {
            name: dataForm.name,
            defaultPoint: dataForm.defaultPoint,
            rate: dataForm.rate,
            image: dataForm.logo,
            countryCode: dataForm.country,
            urlHomePage: dataForm.urlHomePage
          },
        })
      );
    } else {
      await dispatch(
        createCardThunk({
          name: dataForm.name,
          defaultPoint: dataForm.defaultPoint,
          rate: dataForm.rate,
          image: dataForm.logo,
          countryCode: dataForm.country,
          urlHomePage: dataForm.urlHomePage
        })
      ).unwrap();
    }

    refetchData && refetchData();
    handleSuccess && handleSuccess();
  };
  const form = useForm<FormValues>({
    defaultValues: data || {
      name: "",
      logo: undefined,
      defaultPoint: undefined,
      rate: undefined,
    },
    resolver: yupResolver(schema) as any,
  });
  const { register, handleSubmit, formState, getValues, setValue } = form;
  const { errors } = formState;

  const handleChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setValue("logo", file);
      const reader = new FileReader();

      reader.onload = (e) => {
        if (typeof e.target?.result === "string") {
          setImage(e.target.result);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <Wrapper>
      <Title>{data ? "Edit card" : "Add new card"}</Title>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <WrapperInput>
          <WrapperInputTitle>
            <InputTitle>Name</InputTitle>
          </WrapperInputTitle>
          <TextField
            type="text"
            fullWidth
            placeholder="Enter name here"
            inputProps={{
              sx: {
                padding: "12px",
              },
            }}
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
            {...register("name")}
            error={!!errors.name}
            helperText={
              <FormHelperText style={{ fontFamily: "Outfit", margin: "0" }}>
                {errors.name?.message}
              </FormHelperText>
            }
          />
        </WrapperInput>
        <WrapperInput>
          <WrapperInputTitle>
            <InputTitle>Logo</InputTitle>
          </WrapperInputTitle>
          <input
            accept="image/*"
            id="contained-button-file"
            type="file"
            style={{ display: "none" }}
            onChange={handleChangeFile}
            // {...register("logo")}
          />
          {image ? (
            <img src={image} alt="Uploaded" style={{ maxWidth: "100%" }} />
          ) : (
            data && (
              <img
                src={data.logo}
                alt="Uploaded"
                style={{ maxWidth: "100%" }}
              />
            )
          )}
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="primary" component="span">
              Upload
            </Button>
          </label>
        </WrapperInput>
        <WrapperInput>
          <WrapperInputTitle>
            <InputTitle>Country</InputTitle>
          </WrapperInputTitle>
          <TextField
            select
            defaultValue={data?.country || listCountries[0].value}
            fullWidth
            inputProps={{
              sx: {
                padding: "12px",
              },
            }}
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
            {...register("country")}
          >
            {listCountries?.map((item) => (
              <MenuItem value={item.value}>{item.label}</MenuItem>
            ))}
          </TextField>
        </WrapperInput>
        <WrapperInput>
          <WrapperInputTitle>
            <InputTitle>URL</InputTitle>
          </WrapperInputTitle>
          <TextField
            type="text"
            fullWidth
            placeholder="Enter url here"
            inputProps={{
              sx: {
                padding: "12px",
              },
            }}
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
            {...register("urlHomePage")}
            error={!!errors.urlHomePage}
            helperText={
              <FormHelperText style={{ fontFamily: "Outfit", margin: "0" }}>
                {errors.urlHomePage?.message}
              </FormHelperText>
            }
          />
        </WrapperInput>
        <WrapperInput>
          <WrapperInputTitle>
            <InputTitle>Point</InputTitle>
          </WrapperInputTitle>
          <TextField
            type="text"
            fullWidth
            placeholder="Enter point here"
            inputProps={{
              sx: {
                padding: "12px",
              },
            }}
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
            {...register("defaultPoint")}
            error={!!errors.defaultPoint}
            helperText={
              <FormHelperText style={{ fontFamily: "Outfit", margin: "0" }}>
                {errors.defaultPoint?.message}
              </FormHelperText>
            }
          />
        </WrapperInput>
        <WrapperInput>
          <WrapperInputTitle>
            <InputTitle>Mile</InputTitle>
          </WrapperInputTitle>
          <TextField
            type="text"
            fullWidth
            placeholder="Enter mile here"
            inputProps={{
              sx: {
                padding: "12px",
              },
            }}
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
            {...register("rate")}
            error={!!errors.rate}
            helperText={
              <FormHelperText style={{ fontFamily: "Outfit", margin: "0" }}>
                {errors.rate?.message}
              </FormHelperText>
            }
          />
        </WrapperInput>
        {error && <ErrorText>{error}</ErrorText>}
        <WrapperButton>
          <SubmitButton type="submit">Submit</SubmitButton>
        </WrapperButton>
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  margin: 0;
  font-family: Poppins;
`;

const WrapperInput = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const WrapperInputTitle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
`;

const InputTitle = styled.p`
  color: rgba(0, 0, 0, 0.93);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  font-family: Outfit;
`;

const ErrorText = styled.p`
  color: red;
  margin-top: 0;
`;

const WrapperButton = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  flex-direction: column;
  margin-top: 16px;
`;

const SubmitButton = styled.button`
  border-radius: 10px;
  background: #007fff;
  border: none;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 0;
  margin-top: 32px;
  width: 100%;
  cursor: pointer;
  font-family: Outfit;

  :hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
`;

const Form = styled.form`
  width: 100%;
`;

const ErrorImageMessage = styled.p`
  color: #d32f2f;
  font-family: Outfit;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  font-weight: 400;
  font-size: 0.75rem;
`;

export default FormCard;
