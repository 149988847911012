import React from "react";
import { useAppSelector } from "../store";

export enum AuthStepEnum {
  GET_STARTED = "GET_STARTED",
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
  VERIFY_CODE = "VERIFY_CODE",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  RESET_PASSWORD = "RESET_PASSWORD",
  SUCCESS = "SUCCESS",
}

export type AuthStepContextType = {
  step: AuthStepEnum;
  handleSetStep: (value: AuthStepEnum) => void;
};

const defaultValue = {
  step: AuthStepEnum.GET_STARTED,
  handleSetStep: () => {},
};

export const AuthStepContext =
  React.createContext<AuthStepContextType>(defaultValue);

const AuthStepProvider: React.FC<any> = ({ children }: any) => {
  const [step, setStep] = React.useState<AuthStepEnum>(AuthStepEnum.GET_STARTED);

  const handleSetStep = (value: AuthStepEnum) => {
    setStep(value);
  };

  return (
    <AuthStepContext.Provider value={{ step, handleSetStep }}>
      {children}
    </AuthStepContext.Provider>
  );
};

export default AuthStepProvider;
